import {checkValid} from "./functions/ModelsValidate";
import {Order} from "@/components/globalModels/Order";
import {User} from "@/components/globalModels/User";

export function InvoiceSeller() {

  this.checkValid = checkValid

  this.data = {
    Order: new Order(),
    User: new User(),
    fopName: '',
    bankName: '',
    bankSwift: '',
    bankAccount: '',
  }

  this.validation = {
    fopName: false,
    bankName: false,
    bankSwift: false,
    bankAccount: false,
  }

  this.validationTranslate = {
    fopName: '',
    bankName: '',
    bankSwift: '',
    bankAccount: '',
  }

  this.validationTxt = {
    fopName: false,
    bankName: false,
    bankSwift: false,
    bankAccount: false,
  }

}


/*****************   SETTERS   ******************/
InvoiceSeller.prototype.setFopName = function (val) {
  this.data.fopName = val;
}

InvoiceSeller.prototype.setBankName = function (val) {
  this.data.bankName = val;
}

InvoiceSeller.prototype.setBankSwift = function (val) {
  this.data.bankSwift = val;
}

InvoiceSeller.prototype.setBankAccount = function (val) {
  this.data.bankAccount = val;
}

InvoiceSeller.prototype.setItem = function (val) {
  this.data.fopName = val.fop_name ? val.fop_name : ''
  this.data.bankName = val.bank_name ? val.bank_name : ''
  this.data.bankSwift = val.bank_swift ? val.bank_swift : ''
  this.data.bankAccount = val.bank_account ? val.bank_account : ''
}


/*****************   GETTERS   ******************/
InvoiceSeller.prototype.getFopName = function () {
  return this.data.fopName;
}

InvoiceSeller.prototype.getBankName = function () {
  return this.data.bankName;
}

InvoiceSeller.prototype.getBankSwift = function () {
  return this.data.bankSwift;
}

InvoiceSeller.prototype.getBankAccount = function () {
  return this.data.bankAccount;
}


/*****************   VALIDATION   ******************/

InvoiceSeller.prototype.itemValidate = function (isAdmin = false) {

  let validationItems = {
    fopName: this.data.fopName,
    bankName: this.data.bankName,
    bankSwift: this.data.bankSwift,
    bankAccount: this.data.bankAccount,
  }

  let validationOptions = {
    fopName: {type: ['empty']},
    bankName: {type: ['empty']},
    bankSwift: {type: ['empty']},
    bankAccount: {type: ['empty']},
  }

  let userValid = true
  if(isAdmin) {
    userValid = this.data.User.userValidation({userId: true})
  }

  return this.checkValid(validationItems, validationOptions) && userValid
}


InvoiceSeller.prototype.dataInfoPartialValidate = function (
    {
      fopName = true,
      bankName = true,
      bankSwift = true,
      bankAccount = true,
    }
) {

  let validationItems = {
    fopName: this.data.fopName,
    bankName: this.data.bankName,
    bankSwift: this.data.bankSwift,
    bankAccount: this.data.bankAccount,
  }

  let validationOptions = {
    fopName: {type: fopName ? ['latin', 'empty'] : []},
    bankName: {type: bankName ? ['latin'] : []},
    bankSwift: {type: bankSwift ? ['latin', 'empty'] : []},
    bankAccount: {type: bankAccount ? ['latin', 'empty'] : []},
  }

  let userValid = this.data.User.userValidation({userId: true})

  return this.checkValid(validationItems, validationOptions) && userValid
}

InvoiceSeller.prototype.prepareData = function () {
  return {
    user_id : this.data.User.getUserId(),
    fop_name: this.getFopName(),
    bank_name: this.getBankName(),
    bank_swift: this.getBankSwift(),
    bank_account: this.getBankAccount(),
  }
}
