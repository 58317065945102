<template>
  <div class="fragment profiles-tables">


    <ProfilesProformTable
                          v-if="navTabs.products.active"
                          @reload="$emit('reload')"
                          @resetFilter="$emit('resetFilter')"
                          :countFilterParams="countFilterParams"
                          @showMore="$emit('showMore')"
    />
    <ProfilesPackagingTable v-if="navTabs.packaging.active"
                            @reload="$emit('reload')"
                            @resetFilter="$emit('resetFilter')"
                            :countFilterParams="countFilterParams"
                            @showMore="$emit('showMore')"
    />
    <ProfilesLabelTable v-if="navTabs.labelPackaging.active"
                        @reload="$emit('reload')"
                        @resetFilter="$emit('resetFilter')"
                        :countFilterParams="countFilterParams"
                        @showMore="$emit('showMore')"
    />
    <ProfilesExpensesTable v-if="navTabs.expenses.active"
                           @reload="$emit('reload')"
                           @resetFilter="$emit('resetFilter')"
                           :countFilterParams="countFilterParams"
                           @showMore="$emit('showMore')"
    />
    <ProfilesIncomesTable v-if="navTabs.incomes.active"
                           @reload="$emit('reload')"
                           @resetFilter="$emit('resetFilter')"
                           :countFilterParams="countFilterParams"
                           @showMore="$emit('showMore')"
    />
    <ProfilesSimpleProductTable v-if="navTabs.simpleProduct.active"
                                @reload="$emit('reload')"
                                @resetFilter="$emit('resetFilter')"
                                :countFilterParams="countFilterParams"
                                @showMore="$emit('showMore')"

    />
    <ProfilesInvoiceProductTable v-if="navTabs.invoiceProduct.active"
                                @reload="$emit('reload')"
                                @resetFilter="$emit('resetFilter')"
                                :countFilterParams="countFilterParams"
                                @showMore="$emit('showMore')"

    />
    <ProfilesInvoiceSellerTable v-if="navTabs.invoiceSeller.active"
                                @reload="$emit('reload')"
                                @resetFilter="$emit('resetFilter')"
                                :countFilterParams="countFilterParams"
                                @showMore="$emit('showMore')"

    />

    <ProfilesSendFromTable v-if="navTabs.sendFrom.active"
                           @reload="$emit('reload')"
                           @resetFilter="$emit('resetFilter')"
                           :countFilterParams="countFilterParams"
                           @showMore="$emit('showMore')"
    />

  </div>
</template>

<script>
  import ProfilesExpensesTable from "./ProfilesExpensesTable/ProfilesExpensesTable";
  import ProfilesProformTable from "./ProfilesProformTable/ProfilesProformTable";
  import ProfilesPackagingTable from "./ProfilesPackagingTable/ProfilesPackagingTable";
  import ProfilesLabelTable from "./ProfilesLabelTable/ProfilesLabelTable";
  import ProfilesSimpleProductTable from "./ProfilesSimpleProductTable/ProfilesSimpleProductTable";
  import ProfilesIncomesTable from "./ProfilesIncomesTable/ProfilesIncomesTable";
  import ProfilesInvoiceProductTable
    from "@/components/modules/ProfilesModule/components/ProfilesTable/ProfilesTableUser/ProfilesTable/ProfilesInvoiceProductTable/ProfilesInvoiceProductTable";
  import ProfilesInvoiceSellerTable
    from "@/components/modules/ProfilesModule/components/ProfilesTable/ProfilesTableUser/ProfilesTable/ProfilesInvoiceSellerTable/ProfilesInvoiceSellerTable";
  import ProfilesSendFromTable
    from "@/components/modules/ProfilesModule/components/ProfilesTable/ProfilesTableUser/ProfilesTable/ProfilesSendFromTable/ProfilesSendFromTable";


  export default {
    name: "ProfilesTable",

    components: {
      ProfilesSendFromTable,
      ProfilesInvoiceSellerTable,
      ProfilesInvoiceProductTable,
      ProfilesIncomesTable,
      ProfilesLabelTable,
      ProfilesPackagingTable,
      ProfilesProformTable,
      ProfilesExpensesTable,
      ProfilesSimpleProductTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
    },

    data() {
      return {

      }
    },

    methods: {

    }
  }
</script>

<style scoped>
  .profiles-tables >>> .case-cart__title{
    padding-right: 0;
  }
  .profiles-tables >>> .case-cart__subtitle-prof {
    font-size: 14px;
    line-height: 16px;
    margin-top: -5px;
    display: block;
  }

</style>
