<template>
  <div class="check-label-picture">
    <input type="checkbox"
           class="check-label-picture__input"
           :id="'radio-default-' + id"
           :checked="checked"
           :disabled="disabled"
           @change="change"
           :data-row-name="dataValue"
    >
    <label :for="'radio-default-' + id" class="check-label-picture__label">
      <span class="check-label-picture__bg"></span>
      <div class="check-label-picture__label-content">

        <span class="check-label-picture__ico">
          <slot name="ico">

          </slot>
        </span>

        <span class="check-label-picture__txt">

          {{ label }}
        </span>

        <span class="check-label-picture__arrow" v-if="arrow === true">
          <ArrowRightBrownIco/>
        </span>

      </div>
    </label>

    <div class="check-label-picture__help-text">
      {{ helpText }}
    </div>

    <span class="check-label-picture__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>
  </div>
</template>

<script>
  import ArrowRightBrownIco from '../../../../../public/img/UI-group/arrow-right-brown.svg?inline'


  export default {
    name: "CheckLabelPicture",
    components: {
      ArrowRightBrownIco,
    },

    data () {
      return {
        id: null,
        checked: this.value,
      }
    },

    props: [
      'label',
      'value',
      'helpText',
      'errorTxt',
      'error',
      'disabled',
      'name',
      'arrow',
      'dataValue',
    ],

    mounted () {
      this.id = this._uid
    },

    watch: {
      value: function(newVal) {
        this.checked = newVal
      },
      selectedNow: function(newVal) {
        this.checked = newVal
      },
    },

    methods: {

      changeCheckbox(val){
        this.$emit('change', val.target.value);
      },

      change: function() {
        this.checked = !this.checked;
        this.$emit('input', this.checked);
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";
  @import "../../../../scss/colors";


  .check-label-picture{
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    padding-right: 20px;

    &.radio-off{
      margin-right: 38px;

      @include for-550{
        margin-right: 20px;
        padding-right: 5px;
      }

      .check-label-picture__label{
        padding-left: 6px;

        &:before,
        &:after{
          display: none;
        }
      }

      .check-label-picture__bg{
        left: 0;
        width: calc(100% + 24px);
        padding-left: 12px;

        @include for-550{
          width: calc(100% + 10px);
        }
      }
    }

    &__arrow{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -45px;


      @include for-550{
        margin-right: 5px;
        right: -25px;
      }
    }

    &__input{
      display: none;
    }


    &__bg{
      background: white;
      border: 1px solid $mainBg;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px #FDF9F3;
      border-radius: 5px;
      position: absolute;
      left: 24px;
      top: -10px;

      display: flex;
      width: calc(100% - 12px);
      height: calc(100% + 20px);
    }

    &__label-content{
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
    }

    &__label{
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      font-size: 14px;
      line-height: 21px;
      color: $black;


      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -8px;
        width: 15px;
        height: 15px;
        border: 2px solid #BBAD9C;
        box-sizing: border-box;
        border-radius: 3px;
        display: flex;
      }


      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -8px;
        width: 15px;
        height: 15px;
        border: 1px solid transparent;
        border-radius: 3px;
        display: flex;
        background-image: url("../../../../assets/img/UI-group/white-check.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 11px;
        opacity: 0;
      }
    }

    &.white &__label{

      &:before{
        background: white;
        box-shadow: 0px 0px 1px 0px white;
      }

      &:after{

      }
    }

    &__input:checked + &__label{
      &:before{
        background-color: $brown;
        border-color: $brown;
      }

      &:after{
        opacity: 1;
      }
    }

    &__input:disabled + &__label{

      &:before{
        opacity: 0.4;
      }
    }

    &__help-text{
      position: absolute;
      top: 100%;
      left: 24px;
      font-size: 12px;
      line-height: 18px;
      color: $borderBrown;
    }

    &__error{
      position: absolute;
      bottom: -18px;
      left: 0;
      font-size: 11px;
      line-height: 13px;
      color: $orange;
    }


    &__ico{
      display: inline-flex;
      align-items: flex-end;
    }

    &__txt{
      padding-left: 5px;
    }
  }

  .empty-label{
    min-height: 16px;
  }
</style>
