<template>
  <UkrTreasuresCreateUser
          :UT="UT"
          :shippingMethods="shippingMethods"
          @save="save({edit: false})"
  />
</template>

<script>
  import UkrTreasuresCreateUser from "./UkrTreasuresCreateUser/UkrTreasuresCreateUser";
  import {UkrTreasures} from "../../models/UkrTreasures";
  import {ukrTreasuresMixin} from "../../../../../mixins/ukrTreasuresMixins/ukrTreasuresMixin";
  import {TNT_DELIVERY_SERVICE} from "../../../../../staticData/staticVariables";

  export default {
    name: "UkrTreasuresCreate",

    components: {
      UkrTreasuresCreateUser,
    },

    mixins: [ukrTreasuresMixin],

    data() {
      return {
        UT: new UkrTreasures(),
        shippingMethods: [],
      }
    },

    mounted() {
      const query = new this.Query();
      let url = '?';
      let myQuery = query
        .for('posts')

      myQuery.where('DeliveryServiceUseForListings', true)

      url = url + myQuery.limit(100000).page(1).url().split('?')[1]

      this.$store.dispatch('fetchShippingCompany', url).then(response => {
        this.shippingMethods = this.getRespPaginateData(response).filter(item => {
          return item.id !== TNT_DELIVERY_SERVICE.id
        })
      })
    },

    methods: {


    }

  }
</script>

<style scoped>

</style>
