import { render, staticRenderFns } from "./ProfilesSendFromTable.vue?vue&type=template&id=ae1c021c&scoped=true"
import script from "./ProfilesSendFromTable.vue?vue&type=script&lang=js"
export * from "./ProfilesSendFromTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae1c021c",
  null
  
)

export default component.exports