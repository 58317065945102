<template>
  <modal
          @close="$emit('close')"
          class="profiles-product-modal profiles-product"
  >
    <template slot="header">
      <div class="d-flex justify-content-between">
        <div
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['proform_AddingProduct'])"></div>
          {{$t('proform_AddingProduct.localization_value.value')}}
        </div>

        <div
            v-if="historyOfChanged"
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
          <div class="site-link text-decoration-underline btn-style brown"
                @click="historyOfChangesFunc('proform', historyOfChanged)">
            {{$t('common_CheckHistory.localization_value.value')}}
          </div>
        </div>
      </div>


    </template>
    <template slot="body">
      <div class="profiles-product__content">

        <div class="profiles-product__alert mb-4"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['proform_PleaseFill'])"></div>
          {{$t('proform_PleaseFill.localization_value.value')}}
        </div>

        <ProductItemCreation
                :product="product"
                :serverError="serverError"
                :canChangeHSCode="true"
                :altVersion="true"
                @changeCategory="({val, nameVal}) => {changeCategoryProform({val: val, nameVal: nameVal, item: product}, null, autoSetMaterial)}"
                @changeProformSelectItem="changeProformSelectItem(product)"
                :hideAddProfile="true"
        />

      </div>
    </template>
    <template slot="footer">
      <div class="profiles-product__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                v-if="!edit"
                :value="$t('common_create.localization_value.value')"
                class="profiles-product__btn-i"
                @click.native="createProductProform"
                v-bind:class="{'disabled-btn' : $store.getters.getProformDataBtn}"
        />
        <MainButton
                v-else
                :value="$t('common_update.localization_value.value')"
                class="profiles-product__btn-i"
                @click.native="createProductProform"
                v-bind:class="{'disabled-btn' : $store.getters.getProformDataBtn || loading}"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import ProductItemCreation from "../../../../coreComponents/ProductItemCreation/ProductItemCreation";
  import {ProformProduct} from "../../../../globalModels/ProformProduct";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  export default {
    components: {MainButton, Modal, ProductItemCreation},

    mixins: [proformMixins],

    props: {
      id: Number,
      edit: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        product: new ProformProduct,

        loading: false,
        autoSetMaterial: true,
        serverError: false,

        historyOfChanged: null,
      }
    },

    created() {

      if(this.edit) this.loading = true
      if(this.edit) this.autoSetMaterial = false

      this.initializeProductProform().then(() => {

        if(this.edit) {
          const query = new this.Query();
          let url = '?';
          let myQuery = query
            .for('posts')

          myQuery.where('products', 1)
          myQuery.where('ProformEntityIsTemplate', 1)
          myQuery.where('ProformEntityId', this.id)

          url = url + myQuery.url().split('?')[1]

          this.$store.dispatch('getProfilesProform', url).then(() => {
            Object.keys(this.$store.getters.getProfilesProformItem).map(item => {
              this.product.setProfileItem(this.$store.getters.getProfilesProformItem[item], this.isAdmin)

              if(this.$store.getters.getProfilesProformItem[0]?.activityUUID) {
                this.historyOfChanged = this.$store.getters.getProfilesProformItem[0].activityUUID
              }

              this.initialProformSelectOptions({
                val: this.product.product.proformData.proformCategory,
                item: this.product
              }).then(() => {
                this.product.copyStartProfile()
                this.loading = false
                this.autoSetMaterial = true
              })

            })
          }).catch(error => this.createErrorLog(error))


        }
      })
    },

    methods: {
      createProductProform() {
        if(!this.product.profileCreateValidation()) return

        let data = this.product.prepareSaveProform()

        if(Object.keys(this.product.copyProfile).length > 0){
          if(JSON.stringify(data) !== JSON.stringify(this.product.copyProfile))
            data.old_id = this.id
          else {
            this.openNotify('success', 'common_notificationRecordChanged')
            this.$emit('reload')
            this.$emit('close')
            return
          }
        }

        this.$store.dispatch('createSavedProform', data).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            if(this.edit)
              this.openNotify('success', 'common_notificationRecordChanged')
            else
              this.openNotify('success', 'common_notificationRecordCreated')
            this.$emit('reload')
            this.$emit('close')

          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            errors.record_exists ? this.openNotify('error', {txtServer: errors['record_exists'][0]}) : false;

          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      }
    }

  }
</script>

<style lang="scss">
  @import "./AddingProductProfilePopup";
</style>
