<template>
  <div class="fragment">
    <ProfilesTableUser
            :navTabs="navTabs"
            @changeTab="changeTab"
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            :selectHandmade="selectHandmade"
            @resetFilter="resetFilter"
            @changeFilter="changeFilter"
            @showMore="showMore"
            @reload="reload"
    />
  </div>
</template>

<script>
  import ProfilesTableUser from "./ProfilesTableUser/ProfilesTableUser";
  import {radioButtonsFunc} from "../../../../../mixins/creationPageMixins/radioButtonsFunc";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ProfilesTable",

    components: {
      ProfilesTableUser,
    },

    mixins: [routeFilter, radioButtonsFunc],

    data() {
      return {
        forPage: this.$store.getters.getProfilesProformForPage,
        page: 1,
        onePage: 1,
        filterType: 'products',
        filterDate: [],
        filterProformName: '',
        filterProformHandmade: '',
        filterProformHandmadeActive: '',
        filterUser: '',
        filterUserId: '',

        filterPackagingName: '',
        filterPackagingWeight: '',
        filterPackagingWidth: '',
        filterPackagingLength: '',
        filterPackagingHeight: '',

        filterSimpleProductUser: '',
        filterSimpleProductName: '',
        filterSimpleProductNameCyrillic: '',
        filterSimpleProductPrice: '',
        filterSimpleProductCount: '',
        filterSimpleProductHSCode: '',

        filterExpensesName: '',
        filterExpensesType: '',
        filterExpensesTypeId: '',

        countFilterParams: 0,
        filterGetParams: {},

        selectHandmade: [
          {
            label: this.$t('proform_yes.localization_value.value'),
            value: 1,
          },
          {
            label: this.$t('proform_no.localization_value.value'),
            value: 0,
          },
        ],

        navTabs: {
          products: {
            active: true,
            label: this.$t('packaging_profileProducts.localization_value.value'),
            name: 'products',
            permissionVisible: false,
          },
          packaging: {
            active: false,
            label: this.$t('packaging_Packaging.localization_value.value'),
            name: 'packaging',
            permissionVisible: false,
          },

          labelPackaging: {
            active: false,
            label: this.$t('packaging_LabelPackaging.localization_value.value'),
            name: 'labelPackaging',
            permissionVisible: false,
          },

          expenses: {
            active: false,
            label: this.$t('packaging_Expenses.localization_value.value'),
            name: 'expenses',
            permissionVisible: false,
          },

          incomes: {
            active: false,
            label: this.$t('packaging_Incomes.localization_value.value'),
            name: 'incomes',
            permissionVisible: false,
          },

          simpleProduct: {
            active: false,
            label: this.$t('product_SimpleProduct.localization_value.value'),
            name: 'simpleProduct',
            permissionVisible: false,
          },

          invoiceProduct: {
            active: false,
            label: this.$t('common_InvoiceProduct.localization_value.value'),
            name: 'invoiceProduct',
            permissionVisible: true,
          },

          invoiceSeller: {
            active: false,
            label: this.$t('product_InvoiceSeller.localization_value.value'),
            name: 'invoiceSeller',
            permissionVisible: true,
          },

          sendFrom: {
            active: false,
            label: this.$t('fbm_SendFromInformation.localization_value.value'),
            name: 'sendFrom',
            permissionVisible: true,
          },
        },

      }
    },

    computed: {
      user: function() {
        return this.$store.getters.getUserProfile
      }
    },

    watch: {
      user: function(newCount) {
        if(this._.has(newCount, 'id') && this.downloadPermissions) {
          this.checkFilterFromUrl()
        }
      },

      downloadPermissions() {
        if(this.user){
          this.checkTabPermission()
        }
      },
    },

    created() {

    },

    mounted() {
      this.checkFilterFromUrl()
    },

    methods: {

      checkFilterFromUrl() {
        if(this._.has(this.$store.getters.getUserProfile, 'id')){
          if(this.$route.query.type !== undefined){
            this.navTabs.products.active = false
            this.navTabs[this.$route.query.type].active = true
          }

          if(this.downloadPermissions){
            this.checkTabPermission()
          }
        }
      },

      checkTabPermission() {
        this.activeTabByPermissions('navTabs', {
          ['products']: this.PERMISSIONS.PROFILE_PRODUCTS,
          ['packaging']: this.PERMISSIONS.PROFILE_PACKAGING,
          ['labelPackaging']: this.PERMISSIONS.PROFILE_LABEL_PACKAGING,
          ['expenses']: this.PERMISSIONS.PROFILE_EXPENSES,
          ['incomes']: this.PERMISSIONS.PROFILE_INCOME,
          ['simpleProduct']: this.PERMISSIONS.PROFILE_SIMPLE_PRODUCT,
        })

        if(!this._.find(this.navTabs, {active: true}).permissionVisible){
          this.setActivePermissionVisibleTab('navTabs')
          return
        }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          type: this.$route.query.type,
          proformName: this.$route.query.proformName,
          proformHandmade: this.$route.query.proformHandmade,
          user: this.$route.query.user,
          userId: this.$route.query.userId,

          packagingName: this.$route.query.packagingName,
          packagingWeight: this.$route.query.packagingWeight,
          packagingWidth: this.$route.query.packagingWidth,
          packagingLength: this.$route.query.packagingLength,
          packagingHeight: this.$route.query.packagingHeight,

          /**
           * Simple Product profile filter
           */

          simpleProductUser: this.$route.query.simpleProductUser,
          simpleProductName: this.$route.query.simpleProductName,
          simpleProductNameCyrillic: this.$route.query.simpleProductNameCyrillic,
          simpleProductPrice: this.$route.query.simpleProductPrice,
          simpleProductCount: this.$route.query.simpleProductCount,
          simpleProductHSCode: this.$route.query.simpleProductHSCode,

          /**
           * Simple Product profile filter
           */

          expensesName: this.$route.query.expensesName,
          expensesType: this.$route.query.expensesType,
          expensesTypeId: this.$route.query.expensesTypeId,
        }
      },

      reload() {
        this.filter()
      },

      changeTab(tab) {
        this.skipRadioChecked(this.navTabs, 'active')
        this.navTabs[tab].active = true
        this.changeFilter({type: tab})
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if(this.navTabs.products.active) {
          if (next) this.$store.commit('setNextProfilesProformPage', true)
          this.$store.dispatch('fetchProfilesProform', url).then(() => {
            this.$store.commit('setNextProfilesProformPage', false)
          })
        }

        if(this.navTabs.labelPackaging.active) {
          if (next) this.$store.commit('setNextProfilesPackagingPage', true)
          this.$store.dispatch('fetchProfilesPackagingWithPermissions', url).then(() => {
            this.$store.commit('setNextProfilesPackagingPage', false)
          })
        }

        if(this.navTabs.packaging.active) {
          if (next) this.$store.commit('setNextProfilesPackagingPage', true)
          this.$store.dispatch('fetchProfilesPackagingWithPermissions', url).then(() => {
            this.$store.commit('setNextProfilesPackagingPage', false)
          })
        }

        if(this.navTabs.simpleProduct.active) {
          if (next) this.$store.commit('setNextSimpleProductProfilePage', true)
          this.$store.dispatch('fetchSimpleProductProfileWithPermissions', url).then(() => {
            this.$store.commit('setNextSimpleProductProfilePage', false)
          })
        }

        if(this.navTabs.expenses.active) {
          if (next) this.$store.commit('setNextExpensesTypesPage', true)
          this.$store.dispatch('fetchExpensesTypes', url).then(() => {
            this.$store.commit('setNextExpensesTypesPage', false)
          })
        }

        if(this.navTabs.incomes.active) {
          if (next) this.$store.commit('setNextExpensesTypesPage', true)
          this.$store.dispatch('fetchExpensesTypes', url).then(() => {
            this.$store.commit('setNextExpensesTypesPage', false)
          })
        }

        if(this.navTabs.invoiceSeller.active) {
          if (next) this.$store.commit('setNextInvoiceSellerPage', true)
          this.$store.dispatch('fetchInvoiceSeller', url).then(() => {
            this.$store.commit('setNextInvoiceSellerPage', false)
          })
        }

        if(this.navTabs.invoiceProduct.active) {
          if (next) this.$store.commit('setNextInvoiceProductPage', true)
          this.$store.dispatch('fetchInvoiceProduct', url).then(() => {
            this.$store.commit('setNextInvoiceProductPage', false)
          })
        }

        if(this.navTabs.sendFrom.active) {
          if (next) this.$store.commit('setNextOrderSendFromPage', true)
          this.$store.dispatch('fetchOrderSendFrom', url).then(() => {
            this.$store.commit('setNextOrderSendFromPage', false)
          })
        }


        this.checkCountFilter(['user', 'type', 'proformHandmadeActive', 'expensesType'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        if(this.navTabs.products.active){
          myQuery.where('products', 1)
          myQuery.where('ProformEntityIsTemplate', 1)
          // if(this.isAdmin){
          //   myQuery.where('ProformEntityUserId', this.$store.getters.getUserProfile.id)
          // }
          if (this.filterProformName !== '') myQuery.where('search_by_value', this.filterProformName)
          if (this.filterProformHandmade !== '') myQuery.where('ProformEntityHandmade', this.filterProformHandmade)
        }

        if(this.navTabs.packaging.active){
          this.generateFilterQueryParams(
            myQuery,
            {
              filterPackagingName: 'ProformPackagingPackageName',
              filterPackagingWeight: 'ProformPackagingWeight',
              filterPackagingWidth: 'ProformPackagingWidth',
              filterPackagingLength: 'ProformPackagingLength',
              filterPackagingHeight: 'ProformPackagingHeight',
            },
          )
          myQuery.where('ProformPackagingIsTemplate', '1')
        }

        /**
         * Label Packaging profile filter
         */
        if(this.navTabs.labelPackaging.active){
          this.generateFilterQueryParams(
            myQuery,
            {
              filterPackagingName: 'ProformPackagingPackageName',
              filterPackagingWeight: 'ProformPackagingWeight',
              filterPackagingWidth: 'ProformPackagingWidth',
              filterPackagingLength: 'ProformPackagingLength',
              filterPackagingHeight: 'ProformPackagingHeight',
            },
          )
          myQuery.where('order_label_profile', 'fbm')
          // myQuery.where('ProformPackagingIsTemplate', '1')
        }

        /**
         * Simple Product profile filter
         */

        if(this.navTabs.simpleProduct.active){
          this.generateFilterQueryParams(
            myQuery,
            {
              filterSimpleProductUser: 'SimpleProductUserId',
              filterSimpleProductName: 'SimpleProductName',
              filterSimpleProductNameCyrillic: 'SimpleProductNameCyrillic',
              filterSimpleProductPrice: 'SimpleProductPrice',
              filterSimpleProductCount: 'SimpleProductQuantity',
              filterSimpleProductHSCode: 'SimpleProductHsCode',
            },
          )
        }

        /**
         * Expenses filter
         */

        if(this.navTabs.expenses.active){
          this.generateFilterQueryParams(
            myQuery,
            {
              filterExpensesName: 'ExpenseTypeName',
              filterExpensesTypeId: 'ExpenseTypeType',
            },
          )

          myQuery.where('expense', 'true')
        }

        /**
         * Incomes filter
         */

        if(this.navTabs.incomes.active){
          this.generateFilterQueryParams(
              myQuery,
              {
                filterExpensesName: 'ExpenseTypeName',
                filterExpensesTypeId: 'ExpenseTypeType',
              },
          )

          myQuery.where('income', 'true')
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },
    },

  }
</script>

<style scoped>

</style>
