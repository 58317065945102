<template>
  <div>

<!--    <pre>-->
<!--      {{$store.getters.getInvoiceSeller}}-->
<!--    </pre>-->

    <template
            v-if="$store.getters.getInvoiceSeller.length > 0 && $store.getters.getInvoiceSellerLoading !== true">
      <div class="custom-row pt-4">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getInvoiceSeller"
             :key="index"
        >
          <div class="case-cart">
            <div class="case-cart__inner">

              <div class="case-cart__head"></div>
              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                        'common_fopName',
                      ])"></div>
                    <b class="case-cart__subtitle-prof">
                      {{item.fop_name}}
                    </b>
                  </div>
                </div>
                <div class="case-cart__row custom-row">

                  <TableUserNameRow
                          v-if="isAdmin"
                          :item="item"
                  />

                  <div class="case-cart__col custom-col custom-col--50"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                        'common_bankName',
                      ])"></div>
                    <div class="case-cart__label">
                      {{$t('common_bankName.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{item.bank_name}}
                    </div>
                  </div>

                  <div class="case-cart__col custom-col custom-col--50"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                        'common_PricePerItem',
                      ])"></div>
                    <div class="case-cart__label">
                      {{$t('common_bankSwift.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{item.bank_swift}}
                    </div>
                  </div>

                  <div class="case-cart__col custom-col custom-col--50"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                        'common_ItemsQty',
                      ])"></div>
                    <div class="case-cart__label">
                      {{$t('common_bankAccount.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{item.bank_account}}
                    </div>
                  </div>

                </div>
              </div>

              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                          class="secondary-brown"
                          :value="$t('common_delete.localization_value.value')"
                          @click.native="deleteItem(item.id, index)"
                          :ico="true"
                          :typeIco="'delete-brown'"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                          :value="$t('common_edit.localization_value.value')"
                          :ico="true"
                          :typeIco="'edit'"
                          @click.native="editItem(item.id)"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-bottom-btn"
           v-if="$store.getters.getInvoiceSellerCommonList.next_page_url !== null && $store.getters.getInvoiceSeller.length > 0">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getInvoiceSellerCommonList.next_page_url !== null && $store.getters.getInvoiceSeller.length > 0"
                  @click.native="$emit('showMore')"
                  v-bind:class="{'disabled-btn' : $store.getters.getNextInvoiceSellerPage}"
                  :count="$store.getters.getInvoiceSellerCommonList.total - $store.getters.getInvoiceSellerForPage < $store.getters.getInvoiceSellerForPage ?
                $store.getters.getInvoiceSellerCommonList.total - $store.getters.getInvoiceSellerForPage:
                $store.getters.getInvoiceSellerForPage"
          />
        </div>
      </div>
    </template>
    <template
            v-if="$store.getters.getInvoiceSeller.length === 0 && $store.getters.getInvoiceSellerLoading === false">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>


    <AddingInvoiceSellerPopup
            v-if="isModalAddingProfilePopup"
            :id="idSelectedItem"
            :edit="true"
            @close="changeAddingProfilePopup(false)"
            @reload="$emit('reload')"
    />

  </div>
</template>

<script>
  import CaseCartButton from "../../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";
  import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import AddingInvoiceSellerPopup from "../../../../../popups/AddingInvoiceSellerPopup/AddingInvoiceSellerPopup";
  import TableUserNameRow from "../../../../../../../coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";

  export default {
    name: "ProfilesInvoiceSellerTable",
    components: {TableUserNameRow, AddingInvoiceSellerPopup, ShowMore, NoResult, CaseCartButton},

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
        isModalAddingProfilePopup: false,
        idSelectedItem: false,
      }
    },

    mounted() {

    },

    methods: {
      editItem(id) {
        this.idSelectedItem = id
        this.$store.dispatch('getInvoiceSeller', id).then(() => {
          this.changeAddingProfilePopup(true)
        })
      },

      deleteItem(id, index) {
        let text = {
          title: 'common_deleteConfirm',
          txt: 'common_deleteItemAsk',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteInvoiceSeller', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              let profilesPackaging = this.$store.getters.getInvoiceSeller
              profilesPackaging.splice(index, 1)

              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      changeAddingProfilePopup(val) {
        this.isModalAddingProfilePopup = val
      },
    }
  }
</script>

<style scoped>

</style>
