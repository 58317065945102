import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "../../../globalModels/Files";
import {User} from "../../../globalModels/User";
const _ = require('lodash');


export function UkrTreasures() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    status: '',
    date: '',

    User: new User(),

    listingName: '',

    listingPrice: '',

    shipmentMethods: [],

    listingDescription: '',

    authorizePayment: false,


    Files: new Files({
      maxCountFiles: 5,
      maxSizeFiles: 3
    }),



  }

  this.validation = {
    listingName: false,
    listingPrice: false,
    listingDescription: false,
  }

  this.validationTranslate = {
    listingName: '',
    listingPrice: '',
    listingDescription: '',
  }

  this.validationTxt = {
    listingName: false,
    listingPrice: false,
    listingDescription: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getListingName = () => {
    return this.data.listingName
  }
  this.getListingPrice = () => {
    return this.data.listingPrice
  }
  this.getShipmentMethods = () => {
    return this.data.shipmentMethods
  }
  this.getListingDescription = () => {
    return this.data.listingDescription
  }
  this.getAuthorizePayment = () => {
    return this.data.authorizePayment
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }

  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setDate = (val) => {
    this.data.date = val
  }
  this.setListingName = (val) => {
    this.data.listingName = val
  }
  this.setListingPrice = (val) => {
    this.data.listingPrice = val
  }
  this.setShipmentMethods = (val) => {
    this.data.shipmentMethods = val
  }
  this.setListingDescription = (val) => {
    this.data.listingDescription = val
  }
  this.setAuthorizePayment = (val) => {
    this.data.authorizePayment = val
  }

}


/**
 * Global Setters
 */

UkrTreasures.prototype.setItem = function(val, shippingMethods) {

  this.data.User.setUserData(val.user)

  this.setId(val.id)
  this.setListingName(val.name)
  this.setListingPrice(val.price)
  this.setListingDescription(val.description)
  this.setAuthorizePayment(val.use_authorize === 1)
  this.setStatus(val['is_paid'])
  this.setDate(val['created_at'])

  if(val.delivery_services && val.delivery_services.length > 0){
    val.delivery_services.map(item => {
      let deliveryService = _.find(shippingMethods, {id: item.id})
      if(deliveryService){
        this.addShipmentMethod(deliveryService)
      }
    })
  }


}


UkrTreasures.prototype.setUkrTreasuresDownloadFiles = function(val) {
  this.data.Files.pushDownloadFiles(val)
}

UkrTreasures.prototype.removeUkrTreasuresFile = function(id) {
  let i = -1
  _.findIndex(this.data.Files.getDownloadFiles(), function (item, index) {
    if (item[id] !== undefined) i = index
  });

  this.data.Files.removeDownloadFile(i)
}



/**
 * Functions
 */

UkrTreasures.prototype.addShipmentMethod = function(val) {
  this.data.shipmentMethods.push(val)
}

UkrTreasures.prototype.removeShipmentMethod = function(val) {
  let index = _.findIndex(this.data.shipmentMethods, {id: val.id})
  if(index !== undefined){
    this.data.shipmentMethods.splice(index, 1)
  }

}


/**
 * Validations
 */

UkrTreasures.prototype.firstValidation = function () {

  let data = this.data

  let validationItems = {
    listingName: data.listingName,
    listingPrice: data.listingPrice,
    listingDescription: data.listingDescription,
  }

  let validationOptions = {
    listingName: {type: ['empty']},
    listingPrice: {type: ['numeric', 'not-zero', 'empty']},
    listingDescription: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions) && data.Files.fileSizeValidation())
}



/**
 * Prepare Data
 */

UkrTreasures.prototype.prepareSave = function() {

  let data = this.data

  let request = {
    "user_id": data.User.getUserId(),
    "name": data.listingName,
    "description": data.listingDescription,
    // "shipping_description": "standard shipping",
    "price": data.listingPrice,
    "use_authorize": data.authorizePayment,
    "delivery_services": []
  }

  data.shipmentMethods.map(item => {
    request.delivery_services.push({
      "delivery_service_id": item.id
    })
  })

  return request

}
