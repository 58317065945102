import {checkValid} from "../../../../../globalModels/functions/ModelsValidate";
import {User} from "../../../../../globalModels/User";
import {EXPENSES_SYSTEM_TYPES} from "../../../../../../staticData/staticVariables";
const _ = require('lodash');


export function ExpensesTypes() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    name: '',
    type: '',
    isSubType: false,
    subType: '',
    enable: false,
    children: null,

    User: new User(),
  }


  this.validation = {
    id: false,
    name: false,
    type: false,
    enable: false,
  }

  this.validationTranslate = {
    id: '',
    name: '',
    type: '',
    enable: '',
  }

  this.validationTxt = {
    id: false,
    name: false,
    type: false,
    enable: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getName = () => {
    return this.data.name
  }
  this.getType = () => {
    return this.data.type
  }
  this.getSubType = () => {
    return this.data.subType
  }
  this.getEnable = () => {
    return this.data.enable
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setName = (val) => {
    this.data.name = val
  }
  this.setType = (val) => {
    this.data.type = val
  }
  this.setSubType = (val) => {
    this.data.subType = val
  }
  this.setChildren = (val) => {
    this.data.children = val
  }
  this.setIsSubType = (val) => {
    this.data.isSubType = val
  }
  this.setEnable = (val) => {
    this.data.enable = val
  }

}

/**
 * Global Setters
 */

ExpensesTypes.prototype.setItem = function(val) {
  this.setId(val.id)
  this.setName(val.name)
  this.setEnable(val.enable === 1)
  this.setType(_.find(EXPENSES_SYSTEM_TYPES, {value: val.type}))

  if(val?.parent?.children.length > 0) {
    this.setSubType(val?.parent)
    this.setIsSubType(true)
  //   this.setType(this.getType()?.parent)
  }

  if(val?.children.length > 0) {
    this.setChildren(val.children)
  }

  if(val?.user)
    this.data.User.setUser(val.user)
}


/**
 * Prepare Data
 */

ExpensesTypes.prototype.prepareSave = function() {
  let data = {
    name: this.getName(),
    type: this.getType()?.value,
  }

  if (this.getType().value === EXPENSES_SYSTEM_TYPES.BANK_TYPE.value) {
    data['enable'] = this.getEnable()
  }

  if(this.getSubType()?.id && this.data.isSubType) {
    data['parent_id'] = this.getSubType()?.id
  }

  // if(isAdmin){
  //   data.user_id = this.data.User.getUserId()
  // }

  return data
}


/**
 * Validations
 */

ExpensesTypes.prototype.firstValidation = function(isAdmin) {

  let validationItems = {
    name: this.getName(),
    type: this.getType()?.value,
  }

  let validationOptions = {
    name: {type: ['empty']},
    type: {type: ['empty']},
  }

  if(isAdmin && !this.data.User.userValidation({userId: true}))
    return false


  return (this.checkValid(validationItems, validationOptions))
}
