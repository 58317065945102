var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"profiles-package-modal profiles-package",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[(!_vm.edit)?_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'packaging_AddingPackageProfile',
                    ])}}}),_vm._v(" "+_vm._s(_vm.$t('packaging_AddingPackageProfile.localization_value.value'))+" ")]):_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'packaging_DeletingPackageProfile',
                    ])}}}),_vm._v(" "+_vm._s(_vm.$t('packaging_DeletingPackageProfile.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"profiles-package__content"},[_c('div',{staticClass:"profiles-package__alert mb-4",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'packaging_PleaseAillDetail',
                    ])}}}),_vm._v(" "+_vm._s(_vm.$t('packaging_PleaseAillDetail.localization_value.value'))+" ")]),(_vm.$store.getters.getIsAdminRights === 'admin')?_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col custom-col--50 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['shop_user'])}}}),_c('UserSelect',{attrs:{"serverError":_vm.serverError,"validationUser":_vm.validation.userForAdmin,"validationTxtUser":_vm.validationTxt.userForAdmin,"validationTranslateUser":'error_required',"userSelected":_vm.userForAdmin,"typeSelect":'users',"userEmail":true},on:{"changeUser":_vm.changeUsersFilter}})],1)]):_vm._e(),_c('div',{staticClass:"profiles-package__row custom-row"},[_c('div',{staticClass:"profiles-package__col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'packaging_NamePackage',
                    ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.validation.name},attrs:{"label":_vm.$t('packaging_NamePackage.localization_value.value'),"type":'text',"error":_vm.validation.name,"errorTxt":_vm.serverError ? _vm.validationTxt.name: _vm.$t(`${_vm.validationTranslate.name}.localization_value.value`)},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('div',{staticClass:"profiles-package__col custom-col custom-col--25 custom-col--md-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'packaging_Weight',
                    ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.validation.weight},attrs:{"label":_vm.$t('packaging_Weight.localization_value.value'),"type":'text',"error":_vm.validation.weight,"errorTxt":_vm.serverError ? _vm.validationTxt.weight: _vm.$t(`${_vm.validationTranslate.weight}.localization_value.value`),"maxLength":12},model:{value:(_vm.data.weight),callback:function ($$v) {_vm.$set(_vm.data, "weight", $$v)},expression:"data.weight"}})],1),_c('div',{staticClass:"profiles-package__col custom-col custom-col--25 custom-col--md-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['packaging_Width'])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.validation.width},attrs:{"label":_vm.$t('packaging_Width.localization_value.value'),"type":'text',"error":_vm.validation.width,"errorTxt":_vm.serverError ? _vm.validationTxt.width : _vm.$t(`${_vm.validationTranslate.width}.localization_value.value`),"maxLength":12},model:{value:(_vm.data.width),callback:function ($$v) {_vm.$set(_vm.data, "width", $$v)},expression:"data.width"}})],1),_c('div',{staticClass:"profiles-package__col custom-col custom-col--25 custom-col--md-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'packaging_Length',
                    ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.validation.lengthProp},attrs:{"label":_vm.$t('packaging_Length.localization_value.value'),"type":'text',"error":_vm.validation.lengthProp,"errorTxt":_vm.serverError ? _vm.validationTxt.lengthProp : _vm.$t(`${_vm.validationTranslate.lengthProp}.localization_value.value`),"maxLength":12},model:{value:(_vm.data.lengthProp),callback:function ($$v) {_vm.$set(_vm.data, "lengthProp", $$v)},expression:"data.lengthProp"}})],1),_c('div',{staticClass:"profiles-package__col custom-col custom-col--25 custom-col--md-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'packaging_Height',
                    ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.validation.height},attrs:{"label":_vm.$t('packaging_Height.localization_value.value'),"type":'text',"error":_vm.validation.height,"errorTxt":_vm.serverError ? _vm.validationTxt.height : _vm.$t(`${_vm.validationTranslate.height}.localization_value.value`),"maxLength":12},model:{value:(_vm.data.height),callback:function ($$v) {_vm.$set(_vm.data, "height", $$v)},expression:"data.height"}})],1)])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"profiles-package__btn"},[_c('span',{staticClass:"site-link site-link--alt buy-label__btn-i mr-3",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),(!_vm.edit)?_c('MainButton',{staticClass:"profiles-package__btn-i",class:{'disabled-btn' : _vm.$store.getters.getProfilesPackagingBtn},attrs:{"value":_vm.$t('common_create.localization_value.value')},nativeOn:{"click":function($event){return _vm.createPackage.apply(null, arguments)}}}):_c('MainButton',{staticClass:"profiles-package__btn-i",class:{'disabled-btn' : _vm.$store.getters.getProfilesPackagingBtn},attrs:{"value":_vm.$t('common_update.localization_value.value')},nativeOn:{"click":function($event){return _vm.updatePackage.apply(null, arguments)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }