<template>
  <div class="fragment">
    <UkrTreasuresTableUser
            :navTabs="navTabs"
            @changeTab="changeTab"
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            @resetFilter="resetFilter"
            @changeFilter="changeFilter"
            @showMore="showMore"
            @reload="reload"
    />
  </div>
</template>

<script>
  import UkrTreasuresTableUser from "./UkrTreasuresTableUser/UkrTreasuresTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {radioButtonsFunc} from "../../../../../mixins/creationPageMixins/radioButtonsFunc";

  export default {
    name: "UkrTreasuresTable",

    components: {
      UkrTreasuresTableUser,
    },

    mixins: [routeFilter, radioButtonsFunc],

    watch: {
      downloadPermissions() {
        if(this.loadUserAuthorizedData) {
          this.checkTabPermission()
        }
      },
      loadUserAuthorizedData() {
        if(this.downloadPermissions) {
          this.checkTabPermission()
        }
      },
    },

    data() {
      return {
        forPage: this.$store.getters.getUkrTreasuresForPage,
        page: 1,
        onePage: 1,
        filterType: 'all',
        filterTypeSklad: '',
        filterDate: [],
        filterId: '',
        filterName: '',
        filterStatus: '',
        filterPrice: '',
        filterUserName: '',
        filterUserId: '',

        filterCode: '',
        filterItemNumber: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          all: {
            label: this.$t('ukrTreasures_AllListings.localization_value.value'),
            name: 'all',
            id: 1,
            active: true,
            permissionVisible: true,
          },
          activeListings: {
            label: this.$t('ukrTreasures_ActiveProducts.localization_value.value'),
            name: 'activeListings',
            id: 2,
            active: false,
            permissionVisible: false,
          },
          soldListings: {
            label: this.$t('ukrTreasures_SoldProducts.localization_value.value'),
            name: 'soldListings',
            id: 3,
            active: false,
            permissionVisible: false,
          },
          paypalListings: {
            label: this.$t('ukrTreasures_PayPal.localization_value.value'),
            name: 'paypalListings',
            id: 4,
            active: false,
            permissionVisible: true,
          },
        },

        listPeriod: [
          this.$t('unidentifiedP_all.localization_value.value'),
          3,
          7,
          15,
          30,
          130,
          230,
        ],
      }
    },

    mounted() {

      if(this.$route.query.type !== undefined){
        this.navTabs.all.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      if(this.downloadPermissions && this.loadUserAuthorizedData){
        this.checkTabPermission()
      }
    },

    methods: {

      checkTabPermission() {
        console.log(33333);
        this.activeTabByPermissions('navTabs', {
          ['activeListings']: this.PERMISSIONS.PAYMENT_LISTING_ACTIVE,
          ['soldListings']: this.PERMISSIONS.PAYMENT_LISTING_SOLD,
          ['paypalListings']: this.PERMISSIONS.PAYMENT_LISTING_PAYPAL,
        })


        this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])
        if(!this._.find(this.navTabs, {active: true}).permissionVisible){
          this.setActivePermissionVisibleTab('navTabs')
          return

        }

        if (this.$store.getters.getUserProfile?.user_setting?.use_paypal_button !== 1) {
          this.navTabs.paypalListings.permissionVisible = false
        }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          type: this.$route.query.type,
          id: this.$route.query.id,
          name: this.$route.query.name,
          status: this.$route.query.status,
          price: this.$route.query.price,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          code: this.$route.query.code,
          itemNumber: this.$route.query.itemNumber,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if(this.navTabs.paypalListings.active){
          if (next) this.$store.commit('setNextPayPalButtonsPage', true)
          this.$store.dispatch('fetchPayPalButtons', url).then(() => {
            this.$store.commit('setNextPayPalButtonsPage', false)
          })
          return
        }

        this.$store.commit('setUkrTreasuresFilter', window.location.search)

        if (next) this.$store.commit('setNextUkrTreasuresPage', true)
        this.$store.dispatch('fetchUkrTreasures', url).then(() => {
          this.$store.commit('setNextUkrTreasuresPage', false)
        })


        this.checkCountFilter(['userName', 'type'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        console.log(this.filterPrice);

        if(this.navTabs.paypalListings.active){
          this.generateFilterQueryParams(
              myQuery,
              {
                filterId: 'id',
                filterUserId: 'user_id',
                filterCode: 'code',
                filterItemNumber: 'item_number',
              },
          )
        }
        else {
          this.generateFilterQueryParams(
              myQuery,
              {
                filterId: 'id',
                filterName: 'name',
                // filterStatus: 'isPaid',
                filterPrice: 'price',
                filterUserId: 'UserId',
                filterTypeSklad: 'type',
              },
          )
        }

        let deliveryTypeId = -1
        switch (this.filterType) {
          case this.navTabs.activeListings.name:
            deliveryTypeId = 0
            break
          case this.navTabs.soldListings.name:
            deliveryTypeId = 1
            break
          case this.navTabs.paypalListings.name:
            deliveryTypeId = -1
            break
        }

        if (deliveryTypeId !== -1)
          myQuery.where('isPaid', deliveryTypeId)


        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'betweenCreatedAt',
            date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

    }

  }
</script>

<style scoped>

</style>
