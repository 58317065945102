<template>

  <div>
    <template v-if="!_.isUndefined($store.getters.getProfilesProform) && Object.keys($store.getters.getProfilesProform).length > 0 && $store.getters.getProfilesProformLoading !== true">
      <div class="custom-row pt-4">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in profilesProformLocal"
             :key="index"
             :item="item"
        >
          <div class="case-cart">
            <div class="case-cart__inner">

              <div class="case-cart__status" v-if="item.handmade === '1'">
                <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top-left"
                        trigger="hover"
                >
                <span>
                  <StatusIcoBtn :type="'handmade'"/>
                </span>
                  <template slot="popover">
                    <div class="status-tooltip">
                      <StatusIcoBtn :type="'handmade'" class="mr-2"/>
                      {{$t('product_Handmade.localization_value.value')}}
                    </div>
                  </template>
                </v-popover>

              </div>

              <div class="case-cart__head">
                <div class="case-cart__title case-cart__title--status">
              <span v-if="item.name && item.name.translationStorage">
                {{item.name.translationStorage[proformUserConfig.lang].name}}
              </span>
                  <span v-else>—</span>
                </div>
              </div>
              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
                  <TableUserNameRow
                          v-if="isAdmin"
                          :item="item"
                  />

                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label">
                      {{$t('proform_category.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      <template v-if="item.category && item.category.translationStorage">
                        {{item.category.translationStorage[proformUserConfig.lang].name}}
                      </template>
                      <template v-else>—</template>
                    </div>
                  </div>

                  <template v-for="(type, indexType) in item.values">
                    <div class="case-cart__col custom-col custom-col--50"
                         v-if="type.proform_attachment && type.proform_attachment.proform_type.id !== PROFORM_TYPE_NAMING"
                         :key="indexType + 'type'">
                      <div class="case-cart__label">
                        {{type.proform_attachment.proform_type.translationStorage[proformUserConfig.lang].name}}
                      </div>
                      <div class="case-cart__content">
                        {{type.proform_attachment.proform_value.translationStorage[proformUserConfig.lang].name}}
                      </div>
                    </div>
                  </template>


                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label">
                      {{$t('common_PricePerItem.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{item.item_price}}
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label">
                      {{$t('common_ItemsQty.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{item.item_quantity}}
                    </div>
                  </div>

                </div>
              </div>

              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                          class="secondary-brown"
                          :value="$t('common_delete.localization_value.value')"
                          @click.native="deleteItem(item.id)"
                          :ico="true"
                          :typeIco="'delete-brown'"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                          :value="$t('common_edit.localization_value.value')"
                          :ico="true"
                          :typeIco="'edit'"
                          @click.native="editItem(item.id)"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn" v-if="$store.getters.getProfilesProformCommonList.next_page_url !== null && $store.getters.getProfilesProform.length > 0">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getProfilesProformCommonList.next_page_url !== null && $store.getters.getProfilesProform.length > 0"
                  @click.native="$emit('showMore')"
                  v-bind:class="{'disabled-btn' : $store.getters.getNextProfilesProformPage}"
                  :count="$store.getters.getProfilesProformCommonList.total - $store.getters.getProfilesProformForPage < $store.getters.getProfilesProformForPage ?
                $store.getters.getProfilesProformCommonList.total - $store.getters.getProfilesProformForPage:
                $store.getters.getProfilesProformForPage"
          />
        </div>
      </div>
    </template>
    <template v-if="_.isUndefined($store.getters.getProfilesProform) && $store.getters.getProfilesProformLoading === false">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>





    <AddingProductProfilePopup
            v-if="isModalAddingProfilePopup"
            :id="idSelectedItem"
            :edit="true"
            @close="changeAddingProfilePopup(false)"
            @reload="$emit('reload')"
    />

  </div>

</template>

<script>
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import CaseCartButton from "../../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";
  import AddingProductProfilePopup from "../../../../../popups/AddingProductProfilePopup/AddingProductProfilePopup";
  import {PROFORM_TYPE_NAMING} from "../../../../../../../../staticData/staticVariables";
  import TableUserNameRow from "../../../../../../../coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";

  export default {
    name: "ProfilesProformTable",
    components: {
      TableUserNameRow,
      AddingProductProfilePopup, NoResult, ShowMore, CaseCartButton, StatusIcoBtn},

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
        profilesProformLocal: this.$store.getters.getProfilesProform,

        PROFORM_TYPE_NAMING: PROFORM_TYPE_NAMING,
        isModalAddingProfilePopup: false,
        idSelectedItem: false,
      }
    },

    computed: {
      profilesProform() {
        return this.$store.getters.getProfilesProform
      }
    },

    watch: {
      profilesProform: function (newVal) {
        if(!this._.isUndefined(newVal)){
          this.profilesProformLocal = newVal

          if(Object.keys(newVal).length > 0) {
            Object.keys(newVal).map(item => {
              this.profilesProformLocal[item].category =
                newVal[item].values[PROFORM_TYPE_NAMING]?.proform_attachment?.proform_category
              this.profilesProformLocal[item].name =
                newVal[item].values[PROFORM_TYPE_NAMING]?.proform_attachment?.proform_value
            })
          }
        }
      }
    },

    mounted() {

    },

    methods: {
      editItem(id) {
        this.idSelectedItem = id
        this.changeAddingProfilePopup(true)
      },

      deleteItem(id){
        let text = {
          title: 'common_deleteConfirm',
          txt: 'common_deleteItemAsk',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteProfilesProform', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              this.openNotify('success', 'common_notificationRecordDeleted')
              this.$emit('reload')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      changeAddingProfilePopup(val){
        this.isModalAddingProfilePopup = val
      },
    },

  }
</script>

<style scoped>
  .case-cart__content{
    padding-right: 7px;
  }
</style>
