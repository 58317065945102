<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ukrTreasures_OrderID'])"></div>
      <CardLeftBlock
              :backgroundImage="'listing'"
              class="cus-payments"
      >
        <template slot="body">
          <div class="card-detail-left__date mt-1">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ukrTreasures_BackAllListings'])"></div>
                <router-link :to="$store.getters.GET_PATHS.financeUkrTreasuresLink" class="detail-page__head-back">
                  <LinkBack
                          :value="$t('ukrTreasures_BackAllListings.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ukrTreasures_ListingToSell'])"></div>
              {{$t('ukrTreasures_ListingToSell.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ukrTreasures_PleaseFillOut'])"></div>
              {{$t('ukrTreasures_PleaseFillOut.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="custom-row" v-if="isAdmin">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                        :validationUser="UT.data.User.validation.user"
                        :validationTxtUser="UT.data.User.validationTxt.user"
                        :validationTranslateUser="UT.data.User.validationTranslate.user"
                        :userSelected="UT.data.User.user"
                        :typeSelect="'users'"
                        :userEmail="true"
                        @changeUser="changeUser"
                />
              </div>
            </div>
          </div>

          <UkrTreasuresStep
                  :UT="UT"
                  :shippingMethods="shippingMethods"
          />
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
              <router-link :to="$store.getters.GET_PATHS.financeUkrTreasuresLink"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ukrTreasures_AddListing'])"></div>
              <MainButton
                      class="order-create__footer-btn-i wfc"
                      :value="$t('ukrTreasures_AddListing.localization_value.value')"
                      @click.native="$emit('save')"
                      v-bind:class="{'disabled-btn' : $store.getters.getUkrTreasuresBtn}"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import UkrTreasuresStep from "../../components/UkrTreasuresStep";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import {ukrTreasuresMixin} from "../../../../../../mixins/ukrTreasuresMixins/ukrTreasuresMixin";


  export default {
    name: "UkrTreasuresCreateUser",

    components: {
      UserSelect,
      UkrTreasuresStep,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
    },

    mixins: [mixinDetictingMobile, ukrTreasuresMixin],

    props: {
      UT: Object,
      shippingMethods: Array,
    },

    data() {
      return {}
    },

    methods: {}
  }
</script>

<style scoped>

</style>

