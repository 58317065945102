<template>
  <modal
          @close="$emit('close')"
          class="profiles-package-modal profiles-package"
  >
    <template slot="header">
      <div v-if="!edit"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'packaging_AddingPackageProfile',
                      ])"></div>
        {{$t('packaging_AddingPackageProfile.localization_value.value')}}
      </div>
      <div v-else
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'packaging_DeletingPackageProfile',
                      ])"></div>
        {{$t('packaging_DeletingPackageProfile.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="profiles-package__content">

        <div class="profiles-package__alert mb-4"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'packaging_PleaseAillDetail',
                      ])"></div>
          {{$t('packaging_PleaseAillDetail.localization_value.value')}}
        </div>

        <div class="order-create__row custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
          <div class="order-create__col custom-col custom-col--50 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>

            <UserSelect
                    :serverError="serverError"
                    :validationUser="validation.userForAdmin"
                    :validationTxtUser="validationTxt.userForAdmin"
                    :validationTranslateUser="'error_required'"
                    :userSelected="userForAdmin"
                    @changeUser="changeUsersFilter"
                    :typeSelect="'users'"
                    :userEmail="true"
            />
          </div>
        </div>

        <div class="profiles-package__row custom-row">
          <div class="profiles-package__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'packaging_NamePackage',
                      ])"></div>
            <DefaultInput
                    :label="$t('packaging_NamePackage.localization_value.value')"
                    :type="'text'"
                    v-model="data.name"
                    v-bind:class="{'ui-no-valid': validation.name}"
                    :error="validation.name"
                    :errorTxt="serverError ? validationTxt.name: $t(`${validationTranslate.name}.localization_value.value`)"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--25 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'packaging_Weight',
                      ])"></div>
            <DefaultInput
                    :label="$t('packaging_Weight.localization_value.value')"
                    :type="'text'"
                    v-model="data.weight"
                    v-bind:class="{'ui-no-valid': validation.weight}"
                    :error="validation.weight"
                    :errorTxt="serverError ? validationTxt.weight: $t(`${validationTranslate.weight}.localization_value.value`)"
                    :maxLength="12"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--25 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['packaging_Width'])"></div>
            <DefaultInput
                    :label="$t('packaging_Width.localization_value.value')"
                    :type="'text'"
                    v-model="data.width"
                    v-bind:class="{'ui-no-valid': validation.width}"
                    :error="validation.width"
                    :errorTxt="serverError ? validationTxt.width : $t(`${validationTranslate.width}.localization_value.value`)"
                    :maxLength="12"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--25 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'packaging_Length',
                      ])"></div>
            <DefaultInput
                    :label="$t('packaging_Length.localization_value.value')"
                    :type="'text'"
                    v-model="data.lengthProp"
                    v-bind:class="{'ui-no-valid': validation.lengthProp}"
                    :error="validation.lengthProp"
                    :errorTxt="serverError ? validationTxt.lengthProp : $t(`${validationTranslate.lengthProp}.localization_value.value`)"
                    :maxLength="12"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--25 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'packaging_Height',
                      ])"></div>
            <DefaultInput
                    :label="$t('packaging_Height.localization_value.value')"
                    :type="'text'"
                    v-model="data.height"
                    v-bind:class="{'ui-no-valid': validation.height}"
                    :error="validation.height"
                    :errorTxt="serverError ? validationTxt.height : $t(`${validationTranslate.height}.localization_value.value`)"
                    :maxLength="12"
            />
          </div>
        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="profiles-package__btn">

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                v-if="!edit"
                :value="$t('common_create.localization_value.value')"
                v-bind:class="{'disabled-btn' : $store.getters.getProfilesPackagingBtn}"
                class="profiles-package__btn-i"
                @click.native="createPackage"
        />
        <MainButton
                v-else
                :value="$t('common_update.localization_value.value')"
                v-bind:class="{'disabled-btn' : $store.getters.getProfilesPackagingBtn}"
                class="profiles-package__btn-i"
                @click.native="updatePackage"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultInput from '../../../../UI/inputs/DefaultInput/DefaultInput.vue'
  import {validation} from "../../../../../services/validation";
  import UserSelect from "../../../../coreComponents/UserSelect/UserSelect";

  export default {
    name: "AddingPackageProfilePopup",
    components: {
      UserSelect,
      Modal,
      MainButton,
      DefaultInput,
    },

    props: {
      id: Number,
      edit: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        data: {
          name: '',
          weight: '',
          width: '',
          lengthProp: '',
          height: '',
        },

        userForAdmin: '',

        serverError: false,

        validation: {
          name: false,
          weight: false,
          width: false,
          lengthProp: false,
          height: false,
          userForAdmin: false,
        },

        validationTranslate: {
          name: '',
          weight: '',
          width: '',
          lengthProp: '',
          height: '',
          userForAdmin: '',
        },

        validationTxt: {
          name: false,
          weight: false,
          width: false,
          lengthProp: false,
          height: false,
          userForAdmin: false,
        }
      }
    },

    mounted() {
      if (this.edit) {
        this.initializePackage()
      }
    },

    methods: {
      initializePackage() {
        let item = this.$store.getters.getProfilesPackagingItem

        this.data = {
          name: item.package_name,
          weight: item.weight,
          width: item.width,
          lengthProp: item.length,
          height: item.height,
        }

        this.userForAdmin = this.$store.getters.getIsAdminRights === 'admin' ?
          item.user : ''

      },

      changeUsersFilter(value) {
        this.userForAdmin = value
      },

      createPackage() {
        let data = {
          "user_id": this.$store.getters.getIsAdminRights === 'admin' ?
            this.userForAdmin.id : this.$store.getters.getUserProfile.id,
          "package_name": this.data.name,
          "weight": this.data.weight,
          "width": this.data.width,
          "length": this.data.lengthProp,
          "height": this.data.height,
        }

        if (!this.checkValidate()) return

        this.$store.dispatch('createProfilesPackaging', data).then((response) => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordCreated')

            this.$emit('reload')
            this.$emit('close')
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;
            this.serverError = true;
            errors.package_name ? (this.validation.name = true, this.validationTxt.name = errors.package_name[0]) : false;
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },

      updatePackage() {
        let data = {
          "user_id": this.$store.getters.getIsAdminRights === 'admin' ?
            this.userForAdmin.id : this.$store.getters.getUserProfile.id,
          "package_name": this.data.name,
          "weight": this.data.weight,
          "width": this.data.width,
          "length": this.data.lengthProp,
          "height": this.data.height,
        }

        if (!this.checkValidate()) return

        this.$store.dispatch('updateProfilesPackaging', {id: this.id, data: data}).then((response) => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordChanged')

            this.$emit('reload')
            this.$emit('close')
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;
            this.serverError = true;
            errors.package_name ? (this.validation.name = true, this.validationTxt.name = errors.package_name[0]) : false;
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },

      checkValidate() {
        let validationItems = {
          name: this.data.name,
          weight: this.data.weight,
          width: this.data.width,
          lengthProp: this.data.lengthProp,
          height: this.data.height,
        }

        let validationOptions = {
          name: {type: ['empty']},
          weight: {type: ['numeric', 'empty']},
          width: {type: ['numeric', 'empty']},
          lengthProp: {type: ['numeric', 'empty']},
          height: {type: ['numeric', 'empty']},
        }

        if(this.$store.getters.getIsAdminRights === 'admin'){
          validationItems.userForAdmin = this.userForAdmin
          validationOptions.userForAdmin = {type: ['empty']}
        }

        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;
        this.validationTranslate = validate.validationError

        return validate.isValidate
      }
    }
  }

</script>

<style lang="scss">
  @import "../../../../../scss/colors";

  .profiles-package {

    .modal-component__inner {
      max-width: 750px;
    }


    &__content {

    }

    &__txt {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $borderBrown;
      margin-bottom: 30px;
    }

    &__row {
      margin-bottom: -15px;
    }

    &__col {

    }

    &__alert {
      font-size: 12px;
      line-height: 14px;
      color: $black;
    }

    &__btn {
      display: flex;
      align-items: center;
    }

    &__btn-i {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

</style>
