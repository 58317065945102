<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'ukrTreasures_AllListings',
          'ukrTreasures_ActiveProducts',
          'ukrTreasures_SoldProducts',
          'ukrTreasures_PayPal',
        ])"></div>
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getUkrTreasuresLoading === true,}">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-if="item.permissionVisible"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              {{item.label}}
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
                :count="countFilterParams"
                @toggleFilter="$emit('toggleFilter')"
                :showFilter="showFilter"
        />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
        <!--<PeriodOfDaysBlock-->
        <!--:title="'Period of Days'"-->
        <!--:list="listPeriod"-->
        <!--:active="activePeriod"-->
        <!--@changePeriod="changePeriod"-->
        <!--/>-->
      </div>

      <div class="btn-right-block">
        <!--<span>-->
        <!--<MainButton class="btn-fit-content brown ml-3"-->
        <!--:value="'Generate PayPal Button'"-->
        <!--@click.native="changeGenPayPalBtnPopup(true)"-->
        <!--/>-->
        <!--</span>-->

<!--        <div v-if="!navTabs.paypalListings.active" v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate(['ukrTreasures_NewListing'])"></div>-->
<!--          <router-link :to="$store.getters.GET_PATHS.financeUkrTreasuresLinkCreate">-->
<!--            <MainButton class="btn-fit-content ml-3"-->
<!--                        :value="$t('ukrTreasures_NewListing.localization_value.value')"-->
<!--                        :ico="'ico'"-->
<!--            >-->
<!--              <template slot="ico">-->
<!--                <PlusIconSVG class="btn-icon-plus"/>-->
<!--              </template>-->
<!--            </MainButton>-->
<!--          </router-link>-->
<!--        </div>-->
        <div v-if="navTabs.paypalListings.active" v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['ukrTreasures_NewListing'])"></div>
          <router-link :to="$store.getters.GET_PATHS.financeUkrTreasuresPayPalButtonCreate">
            <MainButton class="btn-fit-content ml-3"
                        :value="$t('payPalButtons_generatePayPalButton.localization_value.value')"
                        :ico="'ico'"
            >
              <template slot="ico">
                <PlusIconSVG class="btn-icon-plus"/>
              </template>
            </MainButton>
          </router-link>
        </div>
      </div>
    </div>

    <!--<GenPayPalBtnPopup-->
    <!--v-if="isModalGenPayPalBtnPopup"-->
    <!--@close="changeGenPayPalBtnPopup(false)"-->
    <!--@htmlCode="openHTMLCodePopup"-->
    <!--/>-->


    <CodeHTMLPopup
            v-if="isModalCodeHTMLPopup"
            @close="changeCodeHTMLPopup(false)"
    />

  </div>
</template>


<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import CodeHTMLPopup from "../../../../popups/CodeHTMLPopup/CodeHTMLPopup";


  export default {
    name: "UkrTreasuresHead",
    components: {
      ToggleFilterButton,
      // PeriodOfDaysBlock,
      MainButton,
      PlusIconSVG,
      // GenPayPalBtnPopup,
      CodeHTMLPopup,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    data() {
      return {
        listPeriod: [
          'All',
          7,
          15,
          30,
          130,
          230
        ],
        activePeriod: 1,
        isModalGenPayPalBtnPopup: false,
        isModalCodeHTMLPopup: false,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },

      changePeriod(val) {
        this.activePeriod = val
      },

      changeGenPayPalBtnPopup(val) {
        this.isModalGenPayPalBtnPopup = val
      },

      changeCodeHTMLPopup(val) {
        this.isModalCodeHTMLPopup = val
      },

      openHTMLCodePopup() {
        this.changeGenPayPalBtnPopup(false)
        this.changeCodeHTMLPopup(true)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";

  .light-bg-block {
    display: flex;
    flex-wrap: wrap;
  }

  @include for-992 {
    .btn-left-block {
      margin-bottom: 15px;
    }

    .btn-right-block {
      flex-wrap: wrap;
    }

    .main-button {
      margin-bottom: 10px;
    }

  }

</style>

