<template>
  <modal
          @close="$emit('close')"
          class="profiles-expenses-modal profiles-expenses"
  >
    <template slot="header">
      <div
          v-if="category === 'expense'"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['expenses_AddingExpenses'])"></div>
        {{$t('expenses_AddingExpenses.localization_value.value')}}
      </div>
      <div
          v-else
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['otherIncome_addingOtherIncomeType'])"></div>
        {{$t('otherIncome_addingOtherIncomeType.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="profiles-expenses__content">

        <div class="profiles-expenses__alert mb-4"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['expenses_PleaseFill'])"></div>
          {{$t('expenses_PleaseFill.localization_value.value')}}
        </div>

        <div class="profiles-expenses__row custom-row">
          <div class="profiles-expenses__col custom-col custom-col--50"
               v-if="category === 'expense'"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['expenses_ExpensesPayee', 'expenses_payee', 'expenses_default', 'expenses_bank'])"></div>
            <DefaultSelect
                    :options="options"
                    :label="$t('expenses_ExpensesPayee.localization_value.value')"
                    :otherValue="'translation'"
                    v-bind:class="{'ui-no-valid': EX.validation.type}"
                    :error="EX.validation.type"
                    :errorTxt="$t(`${EX.validationTranslate.type}.localization_value.value`)"
                    :selected="EX.data.type"
                    @change="changeType"
            />
          </div>
          <div class="profiles-expenses__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['expenses_Name'])"></div>
            <DefaultInput
                    :label="$t('expenses_Name.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': EX.validation.name}"
                    :error="EX.validation.name"
                    :errorTxt="$t(`${EX.validationTranslate.name}.localization_value.value`)"
                    v-model="EX.data.name"
            />
          </div>
        </div>

<!--        <div class="order-create__row custom-row" v-if="EX.data.type.value === EXPENSES_TYPES.PAYEE_TYPE.value">-->
<!--          <div class="order-create__col custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>-->

<!--            <UserSelect-->
<!--                :serverError="false"-->
<!--                :validationUser="EX.data.User.validation.userId"-->
<!--                :validationTxtUser="EX.data.User.validationTxt.userId"-->
<!--                :validationTranslateUser="'error_required'"-->
<!--                :userSelected="EX.data.User.user"-->
<!--                @changeUser="changeUsersFilter"-->
<!--                :typeSelect="'users'"-->
<!--                :userEmail="true"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->


        <div class="order-create__row custom-row" v-if="EX.data.isSubType">
          <div class="profiles-expenses__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['expenses_ExpensesPayeeParent',])"></div>
            <DefaultSelect
                :options="optionsSubType"
                :label="$t('expenses_ExpensesPayeeParent.localization_value.value')"
                :optionsLabel="'name'"
                v-bind:class="{'ui-no-valid': EX.validation.subType}"
                :error="EX.validation.type"
                :errorTxt="$t(`${EX.validationTranslate.subType}.localization_value.value`)"
                :selected="EX.data.subType"
                @change="changeSubType"
            />
          </div>
        </div>

        <div class="order-create__row custom-row" v-if="EX.data.type.value === EXPENSES_TYPES.DEFAULT_TYPE.value && !EX.data.children">
          <div class="order-create__col custom-col custom-col--50 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['expenses_howSubtype'])"></span>

            <DefaultCheckbox
                :label="$t('expenses_howSubtype.localization_value.value')"
                :value="EX.data.isSubType"
                @input="EX.data.isSubType = !EX.data.isSubType"
            />
          </div>
        </div>

        <div class="order-create__row custom-row" v-if="EX.data.type.value === EXPENSES_TYPES.BANK_TYPE.value">
          <div class="order-create__col custom-col custom-col--50 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['expenses_enable'])"></span>

            <DefaultCheckbox
                :label="$t('expenses_enable.localization_value.value')"
                :value="EX.data.enable"
                @input="EX.data.enable = !EX.data.enable"
            />
          </div>
        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="profiles-expenses__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                v-if="type === 'create'"
                :value="$t('common_create.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
                v-bind:class="{'disabled-btn' : $store.getters.getExpensesTypesBtn}"
        />
        <MainButton
                v-else
                :value="$t('common_update.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
                v-bind:class="{'disabled-btn' : $store.getters.getExpensesTypesBtn}"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultInput from '../../../../UI/inputs/DefaultInput/DefaultInput.vue'
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {ExpensesTypes} from "./models/ExpensesTypes";
  import {EXPENSES_SYSTEM_TYPES} from "../../../../../staticData/staticVariables";
  // import UserSelect from "../../../../coreComponents/UserSelect/UserSelect";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "AddingExpensesProfilePopup",
    components: {
      DefaultCheckbox,
      // UserSelect,
      Modal,
      MainButton,
      DefaultInput,
      DefaultSelect,
    },

    props: {
      itemId: Number,
      type: String,
      category: String,
    },

    data() {
      return {
        textareaField: '',
        name: '',
        phone: '',
        time: '',
        date: '',
        valueQuantity: '',
        valueSumDisable: '',
        valueSum: '',
        options: [
          EXPENSES_SYSTEM_TYPES.PAYEE_TYPE,
          EXPENSES_SYSTEM_TYPES.DEFAULT_TYPE,
          EXPENSES_SYSTEM_TYPES.BANK_TYPE,
        ],

        optionsSubType: [],

        EXPENSES_TYPES: EXPENSES_SYSTEM_TYPES,

        EX: new ExpensesTypes(),

      }
    },

    mounted() {
      if(this.itemId){
        this.$store.dispatch('getExpensesTypes', this.itemId).then(() => {
          this.EX.setItem(this.$store.getters.getExpensesTypesItem)

          if(this.EX.data.type.value === this.EXPENSES_TYPES.DEFAULT_TYPE.value) {
            this.$store.dispatch('fetchExpensesTypesNoCommit',
                '?filter[ExpenseTypeType]=default_expense&filter[expense]=true&filter[onlyParents]=true&limit=1000').then(response => {
              this.optionsSubType = this.getRespPaginateData(response)
              this._.remove(this.optionsSubType, {
                id: this.EX.getId()
              });
            })
          }
        }).catch(error => this.createErrorLog(error))
      }

      if (this.category === 'expense') {
        this.options = [
          EXPENSES_SYSTEM_TYPES.PAYEE_TYPE,
          EXPENSES_SYSTEM_TYPES.DEFAULT_TYPE,
          EXPENSES_SYSTEM_TYPES.BANK_TYPE,
        ]
      }
      else {
        this.EX.setType(EXPENSES_SYSTEM_TYPES.DEFAULT_INCOME_TYPE)
        // this.options = [
        //   EXPENSES_SYSTEM_TYPES.DEFAULT_INCOME_TYPE,
        // ]
      }
    },

    methods: {

      save() {
        if(!this.EX.firstValidation()) return

        let saveType = 'createExpensesTypes'

        let data = this.EX.prepareSave(this.isAdmin)

        if (this.type === 'update') {
          saveType = 'updateExpensesTypes'
          data = {
            data: data,
            id: this.itemId
          }
        }

        this.$store.dispatch(saveType, data).then((response) => {

          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if (this.type === 'update') {
                this.openNotify('success', 'common_notificationRecordChanged')
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
              }
              this.$emit('close')
              this.$emit('reload')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      changeType(val) {
        this.EX.setType(val)

        if(this.EX.data.type.value === this.EXPENSES_TYPES.DEFAULT_TYPE.value) {
          this.$store.dispatch('fetchExpensesTypesNoCommit',
              '?filter[ExpenseTypeType]=default_expense&filter[expense]=true&filter[onlyParents]=true&limit=1000').then(response => {
                this.optionsSubType = this.getRespPaginateData(response)
          })
        } else {
          this.EX.data.isSubType = false
        }
      },

      changeSubType(val) {
        this.EX.setSubType(val)
      },

      changeUsersFilter(val) {
        this.EX.data.User.setUser(val)
      },

    }
  }

</script>

<style lang="scss">
  @import "./AddingExpensesProfilePopup";
</style>
