<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'packaging_profileProducts',
          'packaging_Packaging',
          'packaging_LabelPackaging',
          'packaging_Expenses',
          'packaging_Incomes',
          'product_SimpleProduct',
          'common_InvoiceProduct',
          'product_InvoiceSeller',
        ])"></div>
      <div class="content-tabs content-tabs--separator content-tabs--scrolling">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-if="item.permissionVisible"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              {{item.label}}
            </div>
          </div>
        </template>
      </div>

      <div class="scrolling-tabs" v-if="hasScrolling">
        <div class="scrolling-tabs-left hide" @click="scrollTabsLeft">❮❮</div>
        <div class="scrolling-tabs-right hide" @click="scrollTabsRight">❯❯</div>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block">
        <span>

          <MainButton class="btn-fit-content ml-3 mb-0"
                      v-if="!(navTabs.products.active && isAdmin) && !(navTabs.sendFrom.active && isAdmin)"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
                      @click.native="changeAddingProfilePopup(true)"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>

        </span>
      </div>
    </div>

    <AddingProductProfilePopup
            v-if="isModalAddingProfilePopup && navTabs.products.active"
            @close="changeAddingProfilePopup(false)"
            @reload="$emit('reload')"
    />

    <AddingPackageProfilePopup
            v-if="isModalAddingProfilePopup && navTabs.packaging.active"
            @close="changeAddingProfilePopup(false)"
            @reload="$emit('reload')"
    />

    <AddingLabelProfilePopup
            v-if="isModalAddingProfilePopup && navTabs.labelPackaging.active"
            @close="changeAddingProfilePopup(false)"
            @reload="$emit('reload')"
    />

    <AddingExpensesProfilePopup
            v-if="isModalAddingProfilePopup && (navTabs.expenses.active || navTabs.incomes.active)"
            :type="'create'"
            :category="navTabs.expenses.active ? 'expense' : 'income'"
            @reload="$emit('reload')"
            @close="changeAddingProfilePopup(false)"
    />

    <AddingSimpleProductProfilePopup
            v-if="isModalAddingProfilePopup && navTabs.simpleProduct.active"
            @close="changeAddingProfilePopup(false)"
            @reload="$emit('reload')"
    />

    <AddingInvoiceProductPopup
            v-if="isModalAddingProfilePopup && navTabs.invoiceProduct.active"
            @close="changeAddingProfilePopup(false)"
            @reload="$emit('reload')"
    />

    <AddingInvoiceSellerPopup
            v-if="isModalAddingProfilePopup && navTabs.invoiceSeller.active"
            @close="changeAddingProfilePopup(false)"
            @reload="$emit('reload')"
    />

    <SendFromDataPopup
            v-if="isModalAddingProfilePopup && navTabs.sendFrom.active"
            @close="changeAddingProfilePopup(false)"
            @reload="$emit('reload')"
    />

  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import AddingProductProfilePopup from "../../../../popups/AddingProductProfilePopup/AddingProductProfilePopup.vue";
  import AddingPackageProfilePopup from "../../../../popups/AddingPackageProfilePopup/AddingPackageProfilePopup.vue";
  import AddingLabelProfilePopup from "../../../../popups/AddingLabelProfilePopup/AddingLabelProfilePopup.vue";
  import AddingExpensesProfilePopup from "../../../../popups/AddingExpensesProfilePopup/AddingExpensesProfilePopup.vue";
  import AddingSimpleProductProfilePopup
    from "../../../../popups/AddingSimpleProductProfilePopup/AddingSimpleProductProfilePopup";
  import AddingInvoiceProductPopup
    from "@/components/modules/ProfilesModule/popups/AddingInvoiceProductPopup/AddingInvoiceProductPopup";
  import AddingInvoiceSellerPopup
    from "@/components/modules/ProfilesModule/popups/AddingInvoiceSellerPopup/AddingInvoiceSellerPopup";
  import SendFromDataPopup from "@/components/coreComponents/Popups/SendFromDataPopup/SendFromDataPopup";
  import {tabsScrollingMixin} from "@/mixins/globalMixins/tabsScrollingMixin";


  export default {
    name: "ProfilesHead",
    components: {
      SendFromDataPopup,
      AddingInvoiceSellerPopup,
      AddingInvoiceProductPopup,
      AddingProductProfilePopup,
      AddingPackageProfilePopup,
      AddingLabelProfilePopup,
      AddingExpensesProfilePopup,
      AddingSimpleProductProfilePopup,
      ToggleFilterButton,
      MainButton,
      PlusIconSVG,
    },

    mixins: [tabsScrollingMixin],

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        isModalAddingProfilePopup: false,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },

      changePeriod(val){
        this.activePeriod = val
      },

      changeAddingProfilePopup(val){
        this.isModalAddingProfilePopup = val
      },

      openHTMLCodePopup(){
        this.changeGenPayPalBtnPopup(false)
        this.changeCodeHTMLPopup(true)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";

  .light-bg-block{
    display: flex;
    flex-wrap: wrap;
  }

  @include for-992{
    .btn-left-block {
      margin-bottom: 15px;
    }

    .btn-right-block{
      flex-wrap: wrap;
    }

    .main-button{
      margin-bottom: 10px;
    }

  }


  .content-tabs__item {
    position: relative;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }

</style>

