<template>
  <div class="table-filter-wrap">
    <template
        v-if="$store.getters.getPayPalButtons.length > 0 && $store.getters.getPayPalButtonsLoading !== true">
      <div class="site-table-wrap products-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'payPalButtons_id',
            'payPalButtons_name',
            'payPalButtons_price',
            'common_manage',
            ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('payPalButtons_id.localization_value.value')}}</th>
            <th>{{$t('payPalButtons_name.localization_value.value')}}</th>
            <th>{{$t('payPalButtons_price.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>
  
          <tr v-for="(item, index) in $store.getters.getPayPalButtons" :key="index">
            <td width="5%">
              <div class="d-flex align-items-center">
                #{{item.id}}
              </div>
            </td>
            <td>
              {{item.name}}
            </td>
            <td>
              {{ item.price }}
            </td>
            <td width="20%">
              <div class="table-right table-manage-list table-manage-list--small"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'common_react',
                'common_edit',
                ])"></div>
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                        :value="$t(`payPalButtons_viewCode.localization_value.value`)"
                        :type="'copy'"
                        @click.native="showPayPalButton(item.id, 'code')"
                    />
                    <LinkButton
                        :value="$t('payPalButtons_viewLink.localization_value.value')"
                        :type="'copy'"
                        @click.native="showPayPalButton(item.id, 'link')"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
  
  
  
          </tbody>
        </table>
  
        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getPayPalButtons"
                 :key="index"
            >
              <UkrTreasuresPayPalButtonsTableMobile
                  :item="item"
                  :index="index"
                  :selectedNow="selectedNow"
                  @showPayPalButton="showPayPalButton"
              />
            </div>
          </div>
        </div>
  
        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getPayPalButtonsCommonList.next_page_url !== null && $store.getters.getPayPalButtons.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextPayPalButtonsPage}"
                :count="$store.getters.getPayPalButtonsCommonList.total - $store.getters.getPayPalButtonsForPage < $store.getters.getPayPalButtonsForPage ?
                    $store.getters.getPayPalButtonsCommonList.total - $store.getters.getPayPalButtonsForPage:
                    $store.getters.getPayPalButtonsForPage"
            />
          </div>
        </div>
      </div>
    </template>

    <PayPalButtonPopup
        v-if="showPayPalButtonPopup"
        :popupType="popupType"
        @close="togglePayPalButtonPopup(false)"
    />

    <template
        v-if="$store.getters.getPayPalButtonsLoading === false && $store.getters.getPayPalButtons.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>
  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import UkrTreasuresPayPalButtonsTableMobile from "./UkrTreasuresPayPalButtonsTableMobile/UkrTreasuresPayPalButtonsTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import PayPalButtonPopup from "../../../../popups/PayPalButtonPopup/PayPalButtonPopup";

  export default {
    name: "UkrTreasuresPayPalButtonsTable",

    components: {
      PayPalButtonPopup,
      NoResult,
      UkrTreasuresPayPalButtonsTableMobile,
      ShowMore,
      LinkButton,
      ManagerButton,
    },

    mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
      selectedNow: Boolean,
    },

    data() {
      return {
        showPayPalButtonPopup: false,
        popupType: '',
      }
    },

    methods: {
      showPayPalButton(id, type) {
        this.popupType = type
        this.$store.dispatch('getPayPalButtons', id).then(() => {
          this.togglePayPalButtonPopup(true)
        })
      },

      togglePayPalButtonPopup(val) {
        this.showPayPalButtonPopup = val
      }
    }

  }
</script>

<style lang="scss" scoped>

  .products-table .site-table tbody td:last-child {
    padding-right: 15px;
  }

  .products-table .ellipsis{
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }

  .clipping-text{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  td:first-child{
    max-width: 33px;
  }

  td:last-child{
    max-width: 120px;
  }

  .comment-item{
    margin: 0 -15px;
    padding: 10px 15px;
    display: block;

    &:nth-child(even){
      background: #F8F4EE;
    }

    &--right-align {
      text-align: right;
    }

    p{
      margin-bottom: 0;
      &:first-child{
        margin-bottom: 5px;
      }
    }
  }

</style>
