<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">
      <!--<div class="table-filter__item"-->
      <!--v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
      <!--<div class="admin-edit" @click="editTranslate(['ukrTreasures_Status'])"></div>-->
      <!--<DefaultSelect-->
      <!--:options="options"-->
      <!--:label="$t('ukrTreasures_Status.localization_value.value')"-->
      <!--/>-->
      <!--</div>-->
      <div class="table-filter__item">
        <DefaultInput
                :label="'Id'"
                :type="'text'"
                v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ukrTreasures_Name'])"></div>
        <DefaultInput
                :label="$t('ukrTreasures_Name.localization_value.value')"
                :type="'text'"
                v-model="name"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ukrTreasures_PricePerItem'])"></div>
        <DefaultInput
                :label="$t('ukrTreasures_PricePerItem.localization_value.value')"
                :type="'text'"
                v-model="price"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_user',])"></div>
        <SearchSelect
                :options="options"
                :label="$t('common_user.localization_value.value')"
                :optionsLabel="'email.contacts[0]'"
                :functionSearch="functionSearchUserFilter"
                :selected="userName"
                :typeSelect="'users'"
                :userEmail="true"
                @change="changeUsersFilter"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ukrTreasures_DateOfCreation'])"></div>
        <DatePickerDefault
                :label="$t('ukrTreasures_DateOfCreation.localization_value.value')"
                v-model="date"
        >
          <template slot="body">
            <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";

  export default {
    name: "UkrTreasuresFilter",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
      // DefaultSelect,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data() {
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        name: this.filterGetParams.contactName ? this.filterGetParams.contactName : '',
        status: this.filterGetParams.status ? this.filterGetParams.status : '',
        price: this.filterGetParams.price ? this.filterGetParams.price : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        options: [],

        filterCounts: [
          'date',
          'id',
          'name',
          'status',
          'price',

          'userName',
          'userId',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.name = newVal.name ? newVal.name : ''
        this.status = newVal.status ? newVal.status : ''
        this.price = newVal.price ? newVal.price : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
      },
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

    },
  }
</script>

<style scoped>

</style>
