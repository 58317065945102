<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <template v-if="popupType === 'code'">
        {{ $t('payPalButtons_popupCodeText.localization_value.value') }}
      </template>
      <template v-else-if="popupType === 'link'">
        {{ $t('payPalButtons_popupLinkText.localization_value.value') }}
      </template>
    </template>
    <template slot="body">
      <div class="custom-popup__content wsbs">
          {{code}}
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex align-items-center flex-wrap mt-4">
        <MainButton
            :value="$t('common_copy.localization_value.value')"
            class="import-transaction__btn-i"
            @click.native="copy(code)"
            :ico="true"
        >
          <template slot="ico">
            <CopyIcoWhite/>
          </template>
        </MainButton>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import CopyIcoWhite from "../../../../../../public/img/UI-group/copy-icon-white.svg?inline"

  export default {
    name: "PayPalButtonPopup",
    components: {
      Modal,
      MainButton,
      CopyIcoWhite,
    },

    props: {
      popupType: String,
    },

    mounted() {
      switch (this.popupType) {
        case 'code': {
          this.code = this.$store.getters.getPayPalButtonsItem.form
          break
        }
        case 'link': {
          this.code = this.$store.getters.getPayPalButtonsItem.link
          break
        }
        default: {
          break
        }
      }
    },

    data(){
      return {
        code: '',
      }
    },

    methods: {
      copy() {
        this.copyToClipboard(this.code)
        this.$emit('close')
      }
    }
  }

</script>

<style scoped lang="scss">
  .wbsb{
    white-space: break-spaces;
    line-height: 24px;
  }

</style>
