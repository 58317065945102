var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"profiles-package-modal profiles-package",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[(!_vm.edit)?_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'product_AddingSimpleProductProfile',
                      ])}}}),_vm._v(" "+_vm._s(_vm.$t('product_AddingSimpleProductProfile.localization_value.value'))+" ")]):_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'product_editSimpleProductProfile',
                      ])}}}),_vm._v(" "+_vm._s(_vm.$t('product_editSimpleProductProfile.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"profiles-package__content"},[(_vm.$store.getters.getIsAdminRights === 'admin')?_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col custom-col--50 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['shop_user'])}}}),_c('UserSelect',{attrs:{"validationUser":_vm.SP.validation.user,"validationTxtUser":_vm.SP.validationTxt.user,"validationTranslateUser":'error_required',"userSelected":_vm.SP.data.user,"typeSelect":'users',"userEmail":true},on:{"changeUser":_vm.changeUsersFilter}})],1)]):_vm._e(),_c('div',{staticClass:"profiles-package__row custom-row"},[_c('div',{staticClass:"profiles-package__col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'product_Name',
                      ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.SP.validation.name},attrs:{"label":_vm.$t('product_simpleProductName.localization_value.value'),"type":'text',"error":_vm.SP.validation.name,"errorTxt":_vm.serverError ? _vm.SP.validationTxt.name: _vm.$t(`${_vm.SP.validationTranslate.name}.localization_value.value`)},model:{value:(_vm.SP.data.name),callback:function ($$v) {_vm.$set(_vm.SP.data, "name", $$v)},expression:"SP.data.name"}})],1),(!_vm.isBelarus || _vm.isAdmin)?_c('div',{staticClass:"profiles-package__col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'product_nameCyrillic',
                      ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('product_nameCyrillic.localization_value.value'),"type":'text'},model:{value:(_vm.SP.data.nameCyrillic),callback:function ($$v) {_vm.$set(_vm.SP.data, "nameCyrillic", $$v)},expression:"SP.data.nameCyrillic"}})],1):_vm._e(),_c('div',{staticClass:"profiles-package__col custom-col custom-col--25 custom-col--md-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'common_PricePerItem',
                      ])}}}),_c('InputSum',{class:{'ui-no-valid': _vm.SP.validation.price},attrs:{"label":_vm.$t('common_PricePerItem.localization_value.value'),"icoType":'dollar',"numeric":true,"placeholder":'0',"errorTxt":_vm.$t(`${_vm.SP.validationTranslate.price}.localization_value.value`),"error":_vm.SP.validation.price},model:{value:(_vm.SP.data.price),callback:function ($$v) {_vm.$set(_vm.SP.data, "price", $$v)},expression:"SP.data.price"}})],1),_c('div',{staticClass:"profiles-package__col custom-col custom-col--25 custom-col--md-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'common_ItemsQty',
                      ])}}}),_c('InputQuantity',{class:{'ui-no-valid': _vm.SP.validation.count},attrs:{"label":_vm.$t('common_ItemsQty.localization_value.value'),"errorTxt":_vm.$t(`${_vm.SP.validationTranslate.count}.localization_value.value`),"error":_vm.SP.validation.count},on:{"change":_vm.changeValueQuantity},model:{value:(_vm.SP.data.count),callback:function ($$v) {_vm.$set(_vm.SP.data, "count", $$v)},expression:"SP.data.count"}})],1),_c('div',{staticClass:"custom-col mb-0"}),(!_vm.isBelarus || _vm.isAdmin)?_c('div',{staticClass:"profiles-package__col custom-col custom-col--50 custom-col--sm-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'proform_hSCodeCode',
                      ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('proform_hSCodeCode.localization_value.value'),"type":'text'},model:{value:(_vm.SP.data.hSCode),callback:function ($$v) {_vm.$set(_vm.SP.data, "hSCode", $$v)},expression:"SP.data.hSCode"}})],1):_vm._e()])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"profiles-package__btn"},[_c('span',{staticClass:"site-link site-link--alt buy-label__btn-i mr-3",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),(!_vm.edit)?_c('MainButton',{staticClass:"profiles-package__btn-i",class:{'disabled-btn' : _vm.$store.getters.getProfilesPackagingBtn},attrs:{"value":_vm.$t('common_create.localization_value.value')},nativeOn:{"click":function($event){return _vm.create.apply(null, arguments)}}}):_c('MainButton',{staticClass:"profiles-package__btn-i",class:{'disabled-btn' : _vm.$store.getters.getProfilesPackagingBtn},attrs:{"value":_vm.$t('common_update.localization_value.value')},nativeOn:{"click":function($event){return _vm.update.apply(null, arguments)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }