<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
          #{{item.id}}
      </div>
    </div>

    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalButtons_name.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.name }}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalButtons_price.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.price }}
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t(`payPalButtons_viewCode.localization_value.value`)"
                :type="'copy'"
                @click.native="$emit('showPayPalButton', item.id, 'link')"
            />

          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('payPalButtons_viewLink.localization_value.value')"
                :type="'copy'"
                @click.native="$emit('showPayPalButton', item.id, 'link')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";

  export default {
    name: "UkrTreasuresPayPalButtonsTableMobile",

    components: {
      LinkButton,
    },

    props: {
      item: Object,
      statusTranslation: String,
      commentsArray: Array,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false
      }
    },

    methods: {

    },

  }
</script>

<style scoped>

</style>
