var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[(_vm.$store.getters.getProfilesPackaging.length > 0 && _vm.$store.getters.getProfilesPackagingLoading !== true)?[_c('div',{staticClass:"custom-row pt-4"},_vm._l((_vm.$store.getters.getProfilesPackaging),function(item,index){return _c('div',{key:index,staticClass:"custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"},[_c('div',{staticClass:"case-cart"},[_c('div',{staticClass:"case-cart__inner"},[_c('div',{staticClass:"case-cart__head"},[_c('div',{staticClass:"case-cart__title case-cart__title--status"},[_c('span',[_vm._v(" "+_vm._s(item.package_name)+" ")])])]),_c('div',{staticClass:"case-cart__body"},[_c('div',{staticClass:"case-cart__row custom-row"},[_c('div',{staticClass:"case-cart__col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'packaging_NamePackage',
                    ])}}}),_c('b',{staticClass:"case-cart__subtitle-prof"},[_vm._v(" "+_vm._s(_vm.$t('packaging_NamePackage.localization_value.value'))+" ")])])]),_c('div',{staticClass:"case-cart__row custom-row"},[(_vm.isAdmin)?_c('TableUserNameRow',{attrs:{"item":item}}):_vm._e()],1),_c('div',{staticClass:"case-cart__row custom-row"},[_c('div',{staticClass:"case-cart__col custom-col custom-col--50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'fbm_DimentionsWeightKG',
                    ])}}}),_c('div',{staticClass:"case-cart__label"},[_vm._v(" "+_vm._s(_vm.$t('fbm_DimentionsWeightKG.localization_value.value'))+" ")]),_c('div',{staticClass:"case-cart__content"},[_vm._v(" "+_vm._s(parseInt(item.weightKg))+" ")])]),_c('div',{staticClass:"case-cart__col custom-col custom-col--50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'fbm_DimentionsWeightGR',
                    ])}}}),_c('div',{staticClass:"case-cart__label"},[_vm._v(" "+_vm._s(_vm.$t('fbm_DimentionsWeightGR.localization_value.value'))+" ")]),_c('div',{staticClass:"case-cart__content"},[_vm._v(" "+_vm._s(parseInt(item.weightGr))+" ")])]),_c('div',{staticClass:"case-cart__col custom-col custom-col--50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'fbm_DimentionsLengthInch',
                    ])}}}),_c('div',{staticClass:"case-cart__label"},[_vm._v(" "+_vm._s(_vm.$t('fbm_DimentionsLengthInch.localization_value.value'))+" ")]),_c('div',{staticClass:"case-cart__content"},[_vm._v(" "+_vm._s(item.length)+" ")])]),_c('div',{staticClass:"case-cart__col custom-col custom-col--50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'fbm_DimentionsWidthInch',
                    ])}}}),_c('div',{staticClass:"case-cart__label"},[_vm._v(" "+_vm._s(_vm.$t('fbm_DimentionsWidthInch.localization_value.value'))+" ")]),_c('div',{staticClass:"case-cart__content"},[_vm._v(" "+_vm._s(item.width)+" ")])]),_c('div',{staticClass:"case-cart__col custom-col custom-col--50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'fbm_DimentionsHeightInch',
                    ])}}}),_c('div',{staticClass:"case-cart__label"},[_vm._v(" "+_vm._s(_vm.$t('fbm_DimentionsHeightInch.localization_value.value'))+" ")]),_c('div',{staticClass:"case-cart__content"},[_vm._v(" "+_vm._s(item.height)+" ")])])])]),_c('div',{staticClass:"case-cart__bottom"},[_c('div',{staticClass:"case-cart__btn ml-auto"},[_c('CaseCartButton',{staticClass:"secondary-brown",attrs:{"value":_vm.$t('common_delete.localization_value.value'),"ico":true,"typeIco":'delete-brown'},nativeOn:{"click":function($event){return _vm.deleteItem(item.id, index)}}})],1),_c('div',{staticClass:"case-cart__btn ml-auto"},[_c('CaseCartButton',{attrs:{"value":_vm.$t('common_edit.localization_value.value'),"ico":true,"typeIco":'edit'},nativeOn:{"click":function($event){return _vm.editItem(item.id)}}})],1)])])])])}),0),(_vm.$store.getters.getProfilesPackagingCommonList.next_page_url !== null && _vm.$store.getters.getProfilesPackaging.length > 0)?_c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"},[(_vm.$store.getters.getProfilesPackagingCommonList.next_page_url !== null && _vm.$store.getters.getProfilesPackaging.length > 0)?_c('ShowMore',{class:{'disabled-btn' : _vm.$store.getters.getNextProfilesPackagingPage},attrs:{"count":_vm.$store.getters.getProfilesPackagingCommonList.total - _vm.$store.getters.getProfilesPackagingForPage < _vm.$store.getters.getProfilesPackagingForPage ?
              _vm.$store.getters.getProfilesPackagingCommonList.total - _vm.$store.getters.getProfilesPackagingForPage:
              _vm.$store.getters.getProfilesPackagingForPage},nativeOn:{"click":function($event){return _vm.$emit('showMore')}}}):_vm._e()],1)]):_vm._e()]:_vm._e(),(_vm.$store.getters.getProfilesPackaging.length === 0 && _vm.$store.getters.getProfilesPackagingLoading === false)?[_c('NoResult',{attrs:{"countFilterParams":_vm.countFilterParams,"title":_vm.$t('common_noDataYet.localization_value.value'),"titleWithFilter":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noDataYetTxt.localization_value.value'),"textWithFilter":_vm.$t('common_noResultSeems.localization_value.value')},on:{"resetFilter":function($event){return _vm.$emit('resetFilter')}}})]:_vm._e(),(_vm.isModalAddingProfilePopup)?_c('AddingLabelProfilePopup',{attrs:{"id":_vm.idSelectedItem,"edit":true},on:{"close":function($event){_vm.isModalAddingProfilePopup = false},"reload":function($event){return _vm.$emit('reload')}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }