<template>
  <div class="fragment">
    <template v-if="$store.getters.getUkrTreasures.length > 0 && $store.getters.getUkrTreasuresLoading !== true">
      <div class="custom-row unidentified-payments-table">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getUkrTreasures"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__status" v-if="item.is_paid === 1">
                <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top-left"
                        trigger="hover"
                >
                <span>
                  <StatusIcoBtn
                          :type="'delivered'"
                          class="mr-1"
                  />
                </span>
                  <template slot="popover">
                    <div class="status-tooltip"
                         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['ukrTreasures_Sold'])"></div>
                      <StatusIcoBtn :type="'delivered'" class="mr-2"/>
                      <span class="mr-3">{{$t('ukrTreasures_Sold.localization_value.value')}}</span>
                    </div>
                  </template>
                </v-popover>
              </div>
              <div class="case-cart__columns">
                <div class="case-cart__img-block">
                  <div class="case-cart__img">
                    <img v-if="item.imageBase64" :src="'data:image/png;base64,' + item.imageBase64" alt="img">
                    <img v-else-if="item.etsy_listing_images" :src="item.etsy_listing_images.split(';')[0]" alt="img">
                    <img v-else src="/img/finance-group/list-no-image.svg" alt="img">
                  </div>
                  <div class="case-cart__number-block">
                  <span class="case-cart__number-i" v-if="item.small_images_file.length > 1">
                    +{{(item.small_images_file.length - 1)}}
                  </span>
                  </div>
                </div>
                <div class="case-cart__content-block">
                  <div class="case-cart__head pl-0">
                    <div class="case-cart__title case-cart__title--status">
                      <a target="_blank" class="case-cart__title" :href="`${UKRAINIAN_TREASURES_SITE}/${item.slug}`">
                        {{item.name}}
                      </a>
                    </div>
                  </div>
                  <div class="case-cart__body pl-0">
                    <div class="case-cart__row custom-row">
                      <TableUserNameRow
                              v-if="isAdmin"
                              :item="item"
                              :colWidth="'custom-col--66'"
                      />
                      <div class="case-cart__col custom-col custom-col--33">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['ukrTreasures_OrderID'])"></div>
                          {{$t('ukrTreasures_OrderID.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          {{ item.id }}
                        </div>
                      </div>
                      <div class="case-cart__col custom-col mb-0"></div>
                      <div class="case-cart__col custom-col custom-col--66">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['ukrTreasures_Created'])"></div>
                          {{$t('ukrTreasures_Created.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          {{ item.created_at | moment("DD MMM, YYYY") }}
                        </div>
                      </div>
                      <div class="case-cart__col custom-col custom-col--33">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['ukrTreasures_Price'])"></div>
                          {{$t('ukrTreasures_Price.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          ${{item.price}}
                        </div>
                      </div>
                      <div class="case-cart__col custom-col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['ukrTreasures_UkrainianTreasuresLink'])"></div>
                          {{$t('ukrTreasures_UkrainianTreasuresLink.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                        <a target="_blank" :href="`${UKRAINIAN_TREASURES_SITE}/${item.slug}`"
                           class="site-link site-link--light text-decoration-underline clear-after">
                              <!--@click="changeLinkEmailPopup(true, item.slug)"-->
                          {{ `${UKRAINIAN_TREASURES_SITE}/` + item.slug }}
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
<!--              <div class="case-cart__bottom"-->
<!--                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['common_copy', 'common_delete', 'common_edit'])"></div>-->
<!--                <div class="case-cart__btn ml-auto"-->
<!--                     v-if="item.is_paid === 1">-->
<!--                  <CaseCartButton-->
<!--                          :value="$t('common_copy.localization_value.value')"-->
<!--                          :ico="true"-->
<!--                          :typeIco="'copy'"-->
<!--                          @click.native="copyListing(item.id)"-->
<!--                  >-->
<!--                  </CaseCartButton>-->
<!--                </div>-->
<!--                <div class="case-cart__btn ml-auto"-->
<!--                     v-if="item.is_paid === 0">-->
<!--                  <CaseCartButton-->
<!--                          class="secondary-brown"-->
<!--                          :value="$t('common_delete.localization_value.value')"-->
<!--                          :ico="true"-->
<!--                          :typeIco="'delete-brown'"-->
<!--                          @click.native="removeItem(item.id, index)"-->
<!--                  >-->
<!--                  </CaseCartButton>-->
<!--                </div>-->
<!--                <div class="case-cart__btn ml-auto"-->
<!--                     v-if="item.is_paid === 0">-->
<!--                  <router-link :to="$store.getters.GET_PATHS.financeUkrTreasuresLink + '/' + item.id">-->
<!--                    <CaseCartButton-->
<!--                            :value="$t('common_edit.localization_value.value')"-->
<!--                            :ico="true"-->
<!--                            :typeIco="'edit'"-->
<!--                    >-->
<!--                    </CaseCartButton>-->
<!--                  </router-link>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>

      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getUkrTreasuresCommonList.next_page_url !== null && $store.getters.getUkrTreasures.length > 0"
                  @click.native="$emit('showMore')"
                  v-bind:class="{'disabled-btn' : $store.getters.getNextUkrTreasuresPage}"
                  :count="$store.getters.getUkrTreasuresCommonList.total - $store.getters.getUkrTreasuresForPage < $store.getters.getUkrTreasuresForPage ?
                  $store.getters.getUkrTreasuresCommonList.total - $store.getters.getUkrTreasuresForPage:
                  $store.getters.getUkrTreasuresForPage"
          />
        </div>
      </div>

    </template>

    <template v-if="$store.getters.getUkrTreasuresLoading === false && $store.getters.getUkrTreasures.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <LinkEmailPopup
            v-if="isModalLinkEmailPopup"
            :link="link"
            @close="changeLinkEmailPopup(false)"
    />
  </div>
</template>

<script>
  // import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LinkEmailPopup from "../../../../popups/LinkEmailPopup/LinkEmailPopup";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import TableUserNameRow from "../../../../../../coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";
  import {UKRAINIAN_TREASURES_SITE} from "../../../../../../../staticData/staticVariables";


  export default {
    name: "UkrTreasuresTable",
    components: {
      TableUserNameRow,
      NoResult,
      ShowMore,
      // CaseCartButton,
      StatusIcoBtn,
      LinkEmailPopup,
    },

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
        isModalLinkEmailPopup: false,
        UKRAINIAN_TREASURES_SITE: UKRAINIAN_TREASURES_SITE,
        link: '',
      }
    },

    methods: {
      changeLinkEmailPopup(val, slug) {
        this.link = `${UKRAINIAN_TREASURES_SITE}/${slug}`
        this.isModalLinkEmailPopup = val
      },

      copyListing(id) {
        this.$store.dispatch('cloneUkrTreasuresListing', id).then(response => {
          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.$emit('reload')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      removeItem(id, index = false) {
        let text = {
          title: 'common_deleteConfirm',
          txt: 'common_deleteItemAsk',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }


        let confirm = () => {

          this.$store.dispatch('deleteUkrTreasures', id).then((response) => {

            if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

            switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                if (index !== false) {
                  this.$store.getters.getUkrTreasures.splice(index, 1)
                }
                // this.$emit('reload')

                this.openNotify('success', 'common_notificationRecordDeleted')
                break
              }
              /**
               * Validation Error
               */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
              /**
               * Undefined Error
               */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }

          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }
  }
</script>

<style scoped>
  .unidentified-payments-table {
    padding-top: 24px;
  }

  .case-cart__content > span, .case-cart__content > .site-link{
    white-space: nowrap;
  }
</style>
