<template>
  <modal
          @close="$emit('close')"
          class="profiles-label-modal profiles-label"
  >
    <template slot="header">
      <div v-if="!edit"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'packaging_AddingPackageLabel',
                      ])"></div>
        {{$t('packaging_AddingPackageLabel.localization_value.value')}}
      </div>
      <div v-else
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'packaging_DeletingPackageLabel',
                      ])"></div>
        {{$t('packaging_EditingPackageLabel.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="profiles-label__content">

        <div class="profiles-package__alert mb-4"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'packaging_PleaseAillDetail',
                      ])"></div>
          {{$t('packaging_PleaseAillDetail.localization_value.value')}}
        </div>

        <div class="order-create__row custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
          <div class="order-create__col custom-col custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>

            <UserSelect
                    :validationUser="PL.data.User.validation.userId"
                    :validationTxtUser="PL.data.User.validationTxt.userId"
                    :validationTranslateUser="'error_required'"
                    :userSelected="PL.data.User.user"
                    @changeUser="changeUsersFilter"
                    :typeSelect="'users'"
                    :userEmail="true"
            />
          </div>
        </div>

        <div class="profiles-label__row custom-row">
          <div class="profiles-label__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['packaging_NamePackage'])"></div>
            <DefaultInput
                    :label="$t('packaging_NamePackage.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': PL.data.Dimensions.validation.name}"
                    :error="PL.data.Dimensions.validation.name"
                    :errorTxt="$t(`${PL.data.Dimensions.validationTranslate.name}.localization_value.value`)"
                    v-model="PL.data.Dimensions.data.name"
            />
          </div>
          <div class="profiles-label__col custom-col custom-col--33 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_DimentionsWeightKG'])"></div>
            <DefaultInput
                    :label="$t('fbm_DimentionsWeightKG.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': PL.data.Dimensions.validation.weightLB}"
                    :error="PL.data.Dimensions.validation.weightLB"
                    :errorTxt="$t(`${PL.data.Dimensions.validationTranslate.weightLB}.localization_value.value`)"
                    v-model="PL.data.Dimensions.data.weightLB"
            />
          </div>
          <div class="profiles-label__col custom-col custom-col--33 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_DimentionsWeightGR'])"></div>
            <DefaultInput
                    :label="$t('fbm_DimentionsWeightGR.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': PL.data.Dimensions.validation.weightOZ}"
                    :error="PL.data.Dimensions.validation.weightOZ"
                    :errorTxt="$t(`${PL.data.Dimensions.validationTranslate.weightOZ}.localization_value.value`)"
                    v-model="PL.data.Dimensions.data.weightOZ"
            />
          </div>
          <div class="profiles-label__col custom-col custom-col--33 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_DimentionsWidth'])"></div>
            <DefaultInput
                    :label="$t('fbm_DimentionsWidth.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': PL.data.Dimensions.validation.width}"
                    :error="PL.data.Dimensions.validation.width"
                    :errorTxt="$t(`${PL.data.Dimensions.validationTranslate.width}.localization_value.value`)"
                    v-model="PL.data.Dimensions.data.width"
            />
          </div>
          <div class="profiles-label__col custom-col custom-col--33 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_DimentionsHeight'])"></div>
            <DefaultInput
                    :label="$t('fbm_DimentionsHeight.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': PL.data.Dimensions.validation.height}"
                    :error="PL.data.Dimensions.validation.height"
                    :errorTxt="$t(`${PL.data.Dimensions.validationTranslate.height}.localization_value.value`)"
                    v-model="PL.data.Dimensions.data.height"
            />
          </div>
          <div class="profiles-label__col custom-col custom-col--33 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_DimentionsLength'])"></div>
            <DefaultInput
                    :label="$t('fbm_DimentionsLength.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': PL.data.Dimensions.validation.dimensionLength}"
                    :error="PL.data.Dimensions.validation.dimensionLength"
                    :errorTxt="$t(`${PL.data.Dimensions.validationTranslate.dimensionLength}.localization_value.value`)"
                    v-model="PL.data.Dimensions.data.dimensionLength"
            />
          </div>
        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="profiles-label__btn">

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                v-if="!edit"
                :value="$t('common_create.localization_value.value')"
                v-bind:class="{'disabled-btn' : $store.getters.getProfilesPackagingBtn}"
                class="profiles-package__btn-i"
                @click.native="savePackage"
        />
        <MainButton
                v-else
                :value="$t('common_update.localization_value.value')"
                v-bind:class="{'disabled-btn' : $store.getters.getProfilesPackagingBtn}"
                class="profiles-package__btn-i"
                @click.native="savePackage(true)"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultInput from '../../../../UI/inputs/DefaultInput/DefaultInput.vue'
  import {PackingLabel} from "./model/PackingLabel";
  import UserSelect from "../../../../coreComponents/UserSelect/UserSelect";

  export default {
    name: "AddingLabelProfilePopup",
    components: {
      UserSelect,
      Modal,
      MainButton,
      DefaultInput,
    },

    props: {
      edit: {
        type: Boolean,
        default: false,
      },
      idSelectedItem: Number,
    },

    data(){
      return {
        textareaField: '',
        name: '',
        phone: '',
        time: '',
        date: '',
        valueQuantity: '',
        valueSumDisable: '',
        valueSum: '',
        options: [{}],

        PL: new PackingLabel(),
      }
    },

    mounted() {
      if (this.edit) {
        this.initializePackage()
      } else {
        if(!this.isAdmin){
          this.PL.data.User.setUser(this.loadUserAuthorizedData?.user)
          console.log(this.PL.data.User);
        }
      }
    },

    methods: {
      initializePackage() {
        let item = this.$store.getters.getProfilesPackagingItem

        this.PL.setItem(item, this.isAdmin ? item.user : this.loadUserAuthorizedData?.user)
      },

      changeUsersFilter(user) {
        this.PL.data.User.setUser(user)
      },

      changeValueQuantity(data) {
        this.valueQuantity = data
      },

      changeValueSum(data) {
        this.valueSum = data
      },

      savePackage(){

        if (!this.PL.packingLabelValidation(this.isAdmin)) return

        let data = this.PL.prepareSaveMetric()

        let saveType = 'createProfilesPackaging'

        if(this.edit){
          saveType = 'updateProfilesPackaging'
          data = {id: this.$store.getters.getProfilesPackagingItem.id, data: data}
        }

        this.$store.dispatch(saveType, data).then((response) => {
          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if(this.edit){
                this.openNotify('success', 'common_notificationStatusChanged')
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
              }

              this.$emit('reload')
              this.$emit('close')

              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      }

    }
  }

</script>

<style lang="scss">
  @import "./AddingLabelProfilePopup";
</style>
