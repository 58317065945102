<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ukrTreasures_GeneralInformation'])"></div>
      {{$t('ukrTreasures_GeneralInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ukrTreasures_Name'])"></div>
        <DefaultInput
                :label="$t('ukrTreasures_Name.localization_value.value')"
                v-bind:class="{'ui-no-valid': UT.validation.listingName}"
                :error="UT.validation.listingName"
                :errorTxt="$t(`${UT.validationTranslate.listingName}.localization_value.value`)"
                :type="'text'"
                v-model="UT.data.listingName"
        />
      </div>


      <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ukrTreasures_PricePerItem'])"></div>
        <InputSum
                :label="$t('ukrTreasures_PricePerItem.localization_value.value')"
                v-bind:class="{'ui-no-valid': UT.validation.listingPrice}"
                :error="UT.validation.listingPrice"
                :errorTxt="$t(`${UT.validationTranslate.listingPrice}.localization_value.value`)"
                :icoType="'dollar'"
                :placeholder="'0'"
                :value="setPriceValue(UT.data.listingPrice)"
                :max="10000"
                v-model="UT.data.listingPrice"
        />
      </div>
    </div>
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ukrTreasures_ShipmentMethod'])"></div>
      {{$t('ukrTreasures_ShipmentMethod.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100 mb-3"
           v-for="(item, index) in shippingMethods"
           :key="index"
           v-bind:class="{'custom-col--16' : item.id !== 'landmark'}"
      >
        <CheckLabelPicture
                :label="item.name"
                :value="_.find(UT.data.shipmentMethods, {id: item.id})"
                @input="changeShipmentMethod(item)"
        >


          <template slot="ico">

            <div class="ship-company-logo" v-html="item.svg_logo"></div>


            <!--<img v-if="item.id === DHL_DELIVERY_SERVICE.id"-->
                    <!--src="/img/company-icons-group/listing-dhl-icon.png" alt="ico">-->
            <!--<img v-if="item.id === FEDEX_DELIVERY_SERVICE.id"-->
                    <!--src="/img/company-icons-group/listing-fedex-icon.png" alt="ico">-->
            <!--<img v-if="item.id === TNT_DELIVERY_SERVICE.id"-->
                    <!--src="/img/company-icons-group/listing-tnt-icon.png" alt="ico">-->
          </template>
        </CheckLabelPicture>
      </div>
      <!--<div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
        <!--<CheckLabelPicture-->
                <!--:label="'DHL'"-->
        <!--&gt;-->
          <!--<template slot="ico">-->
            <!--<img src="/img/company-icons-group/listing-dhl-icon.png" alt="ico">-->
          <!--</template>-->
        <!--</CheckLabelPicture>-->
      <!--</div>-->


      <!--<div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-0"></div>-->

      <!--<div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
        <!--<CheckLabelPicture-->
                <!--:label="'TNT'"-->
        <!--&gt;-->
          <!--<template slot="ico">-->
            <!--<img src="/img/company-icons-group/listing-tnt-icon.png" alt="ico">-->
          <!--</template>-->
        <!--</CheckLabelPicture>-->
      <!--</div>-->

      <!--<div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
        <!--<CheckLabelPicture-->
                <!--:label="'Landmark (from Kyiv)'"-->
        <!--&gt;-->
          <!--<template slot="ico">-->
            <!--<img src="/img/company-icons-group/listing-landmark-icon.png" alt="ico">-->
          <!--</template>-->
        <!--</CheckLabelPicture>-->
      <!--</div>-->
      <!--<div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-0"></div>-->

    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ukrTreasures_ProductDescription'])"></div>
      {{$t('ukrTreasures_ProductDescription.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row max-1003">
      <div class="order-create__col custom-col custom-col--83 custom-col--sm-100">
        <TextEditor
                v-bind:class="{'ui-no-valid': UT.validation.listingDescription}"
                :error="UT.validation.listingDescription"
                :errorTxt="$t(`${UT.validationTranslate.listingDescription}.localization_value.value`)"
                :value="UT.data.listingDescription"
                @input="inputTextEditor"
        />
      </div>
      <div class="order-create__col custom-col d-flex"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'ukrTreasures_PaymentThroughAutoruze',
          'ukrTreasures_PaymentAuthorize',
          'ukrTreasures_PaymentFromPaypal'
          ])"></div>
        <DefaultCheckbox
                :label="$t('ukrTreasures_PaymentThroughAutoruze.localization_value.value')"
                :value="UT.data.authorizePayment"
                @input="UT.data.authorizePayment = !UT.data.authorizePayment"
        >
          <template slot="content">
            <v-popover
                    class="site-tooltip site-tooltip--radio-after"
                    :disabled="!tooltipAddProductActive"
                    offset="5"
                    placement="top-left"
                    trigger="hover"

            >

              <TooltipBtn/>

              <template slot="popover">
                <p>
                  <b>{{$t('ukrTreasures_PaymentAuthorize.localization_value.value')}}</b>
                </p>
                <p>
                  {{$t('ukrTreasures_PaymentFromPaypal.localization_value.value')}}
                </p>
              </template>
            </v-popover>
          </template>
        </DefaultCheckbox>


      </div>
    </div>


    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ukrTreasures_Photos'])"></div>
      {{$t('ukrTreasures_Photos.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row"
         v-if="UT.data.Files.data.maxCountFiles - Object.keys(UT.data.Files.data.downloadFiles).length > 0"
    >
      <div class="order-create__col custom-col">
        <DropZone class="drop-zone-bg"
                  :parentFiles="UT.data.Files.data.files"
                  :multiple="true"
                  :maxCount="UT.data.Files.data.maxCountFiles - UT.data.Files.data.downloadFiles.length"
                  :maxSize="UT.data.Files.data.maxSizeFiles"
                  :accept="'image/x-png,image/jpeg,'"
                  @changeImg="changeImg"
        />
      </div>
    </div>

    <div class="custom-row product-img-row"
         v-if="Object.keys(UT.data.Files.data.downloadFiles).length > 0"
         :key="images"
    >
      <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
           v-for="(file, indexFile) in UT.data.Files.data.downloadFiles"
           :key="indexFile"
      >
        <div class="site-document d-flex justify-content-center">
          <div class="site-document__remove" @click="removeDocument(parseInt(_.first(Object.keys(file))) + 1)">
            <CloseIcon/>
          </div>
          <div class="site-document__img">
            <img :src="'data:image/png;base64,' + file[_.first(Object.keys(file))]"
                 @click="showSingle(_.first(Object.keys(file)))" alt="img">
          </div>
        </div>
      </div>

    </div>

    <!-- all props & events -->
    <Lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
    ></Lightbox>

  </div>
</template>

<script>
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import InputSum from "../../../../UI/inputs/InputSum/InputSum";
  import CheckLabelPicture from "../../../../UI/checkboxes/CheckLabelPicture/CheckLabelPicture";
  import TextEditor from "../../../../coreComponents/TextEditor/TextEditor";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TooltipBtn from "../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import DropZone from "../../../../coreComponents/DropZone/DropZone";
  import {VPopover} from "v-tooltip";
  import CloseIcon from '../../../../../../public/img/modal-group/close-icon.svg?inline'
  import {
    DHL_DELIVERY_SERVICE,
    FEDEX_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE
  } from "../../../../../staticData/staticVariables";
  import Lightbox from 'vue-easy-lightbox'

  export default {
    name: "UkrTreasuresStep",

    components: {
      DropZone,
      TooltipBtn,
      DefaultCheckbox,
      TextEditor,
      CheckLabelPicture,
      InputSum,
      DefaultInput,
      VPopover,
      CloseIcon,
      Lightbox,
    },

    props: {
      UT: Object,
      shippingMethods: Array,
    },

    data() {
      return {
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,

        shipmentMethods: [
          DHL_DELIVERY_SERVICE,
          FEDEX_DELIVERY_SERVICE,
          TNT_DELIVERY_SERVICE,
        ],

        tooltipAddProductActive: true,


        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, // default:

        images: 0,
      }
    },

    methods: {

      inputTextEditor(val) {
        this.UT.setListingDescription(val)
      },

      changeImg(files, maxSizeError) {
        this.UT.data.Files.setFiles(files)

        this.UT.data.Files.setFilesMaxSizeError(maxSizeError)
      },

      changeShipmentMethod(val){
        console.log(23423);
        console.log(this._.find(this.UT.getShipmentMethods(), {id: val.id}));

        if(!this._.find(this.UT.getShipmentMethods(), {id: val.id})){
          this.UT.addShipmentMethod(val)
        } else {
          this.UT.removeShipmentMethod(val)
        }
      },

      handleHide() {
        this.visible = false
      },

      show() {
        this.visible = true
      },

      showSingle(id) {
        this.$store.dispatch('getFileFromServer', id).then((response) => {
          this.imgs = []
          this.imgs.push({
            title: 'img',
            src: `data:image/png;base64,` + response[id]
          })
          this.show()
        })
      },

      removeDocument(id) {
        this.$store.dispatch('removeUserDocument', id).then(() => {
          this.UT.removeUkrTreasuresFile(id - 1)
          this.images = id
        })
      },

    }
  }
</script>

<style scoped lang="scss">
  .ship-company-logo{
    width: 40px;
    height: 17px;
    display: flex;
    align-items: center;
  }

  /deep/ .ship-company-logo svg{
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .max-1003{
    max-width: 1003px;
  }
</style>
