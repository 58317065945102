<template>
  <div>
    <UkrTreasuresHead
            :showFilter="showFilter"
            :navTabs="navTabs"
            :countFilterParams="countFilterParams"
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @changeTab="(data) => $emit('changeTab', data)"
            @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <UkrTreasuresFilter
          v-if="!navTabs.paypalListings.active"
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <UkrTreasuresPayPalButtonsFilter
          v-else
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <UkrTreasuresTable
          v-if="!navTabs.paypalListings.active"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @downloadFiles="$emit('downloadFiles')"
      />

      <UkrTreasuresPayPalButtonsTable
          v-else
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>

  import UkrTreasuresHead from "./UkrTreasuresHead/UkrTreasuresHead";
  import UkrTreasuresFilter from "./UkrTreasuresFilter/UkrTreasuresFilter";
  import UkrTreasuresTable from "./UkrTreasuresTable/UkrTreasuresTable";
  import UkrTreasuresPayPalButtonsTable from "./UkrTreasuresPayPalButtonsTable/UkrTreasuresPayPalButtonsTable";
  import UkrTreasuresPayPalButtonsFilter from "./UkrTreasuresPayPalButtonsFilter/UkrTreasuresPayPalButtonsFilter";

  export default {
    name: "UkrTreasuresTableUser",

    components: {
      UkrTreasuresPayPalButtonsFilter,
      UkrTreasuresPayPalButtonsTable,
      UkrTreasuresHead,
      UkrTreasuresFilter,
      UkrTreasuresTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

    }
  }
</script>

<style scoped>

</style>
