var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-filter-wrap"},[(_vm.$store.getters.getPayPalButtons.length > 0 && _vm.$store.getters.getPayPalButtonsLoading !== true)?[_c('div',{staticClass:"site-table-wrap products-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'payPalButtons_id',
          'payPalButtons_name',
          'payPalButtons_price',
          'common_manage',
          ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('payPalButtons_id.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('payPalButtons_name.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('payPalButtons_price.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('common_manage.localization_value.value')))])])]),_c('tbody',_vm._l((_vm.$store.getters.getPayPalButtons),function(item,index){return _c('tr',{key:index},[_c('td',{attrs:{"width":"5%"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" #"+_vm._s(item.id)+" ")])]),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.price)+" ")]),_c('td',{attrs:{"width":"20%"}},[_c('div',{staticClass:"table-right table-manage-list table-manage-list--small",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
              'common_react',
              'common_edit',
              ])}}}),_c('ManagerButton',{key:_vm.$store.getters.getGlobalUpdateManagerButton,on:{"reloadManagerBtn":function($event){_vm.$store.commit('setGlobalUpdateManagerButton', Math.random())}}},[_c('template',{slot:"item"},[_c('LinkButton',{attrs:{"value":_vm.$t(`payPalButtons_viewCode.localization_value.value`),"type":'copy'},nativeOn:{"click":function($event){return _vm.showPayPalButton(item.id, 'code')}}}),_c('LinkButton',{attrs:{"value":_vm.$t('payPalButtons_viewLink.localization_value.value'),"type":'copy'},nativeOn:{"click":function($event){return _vm.showPayPalButton(item.id, 'link')}}})],1)],2)],1)])])}),0)]):_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list"},_vm._l((_vm.$store.getters.getPayPalButtons),function(item,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('UkrTreasuresPayPalButtonsTableMobile',{attrs:{"item":item,"index":index,"selectedNow":_vm.selectedNow},on:{"showPayPalButton":_vm.showPayPalButton}})],1)}),0)]),_c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"},[(_vm.$store.getters.getPayPalButtonsCommonList.next_page_url !== null && _vm.$store.getters.getPayPalButtons.length > 0)?_c('ShowMore',{class:{'disabled-btn' : _vm.$store.getters.getNextPayPalButtonsPage},attrs:{"count":_vm.$store.getters.getPayPalButtonsCommonList.total - _vm.$store.getters.getPayPalButtonsForPage < _vm.$store.getters.getPayPalButtonsForPage ?
                  _vm.$store.getters.getPayPalButtonsCommonList.total - _vm.$store.getters.getPayPalButtonsForPage:
                  _vm.$store.getters.getPayPalButtonsForPage},nativeOn:{"click":function($event){return _vm.$emit('showMore')}}}):_vm._e()],1)])])]:_vm._e(),(_vm.showPayPalButtonPopup)?_c('PayPalButtonPopup',{attrs:{"popupType":_vm.popupType},on:{"close":function($event){return _vm.togglePayPalButtonPopup(false)}}}):_vm._e(),(_vm.$store.getters.getPayPalButtonsLoading === false && _vm.$store.getters.getPayPalButtons.length === 0)?[_c('NoResult',{attrs:{"countFilterParams":_vm.countFilterParams,"title":_vm.$t('common_noDataYet.localization_value.value'),"titleWithFilter":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noDataYetTxt.localization_value.value'),"textWithFilter":_vm.$t('common_noResultSeems.localization_value.value')},on:{"resetFilter":function($event){return _vm.$emit('resetFilter')}}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }