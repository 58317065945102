<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      Code for HTML
    </template>
    <template slot="body">
      <div class="custom-popup__content wsbs">
        {{code}}
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex align-items-center flex-wrap mt-4">
        <MainButton
            :value="'Copy'"
            class="import-transaction__btn-i"
            @click.native="$emit('close')"
            :ico="true"
        >
          <template slot="ico">
            <CopyIcoWhite/>
          </template>
        </MainButton>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  // import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import CopyIcoWhite from "../../../../../../public/img/UI-group/copy-icon-white.svg?inline"

  export default {
    name: "GenPayPalBtnPopup",
    components: {
      Modal,
      MainButton,
      // DefaultInput,
      CopyIcoWhite
    },

    data(){
      return {
        files: [],
        text: 'Please be careful with the vase inside!',
        name: '',
        sum: '',
        code: '<form target=”_top”action=”https://www.paypal.com/cgi-bin/webser”method=”post”> \n' +
          '<input type=”hidden”name=’cmd”value=”_s-xclick”> \n' +
          '<input type=”hidden” name=”hosted_button_id”value=”BFYFXDFLE#L”> \n' +
          '<input type=”image” src=”https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif” border=”0” name=”submit”al=””> \n' +
          '<img alt=”” border=”0” src=”https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif” width=”1” height\\”1”> \n'
      }
    },

    mounted() {

    },

    methods: {
      changeImg(files) {
        this.files = files
      },
    }
  }

</script>

<style scoped lang="scss">
  .wbsb{
    white-space: break-spaces;
    line-height: 24px;
  }

</style>
