<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">


      <div class="table-filter__item"
           v-if="navTabs.products.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_Naming'])"></span>
        <DefaultInput
                :label="$t('proform_Naming.localization_value.value')"
                :type="'text'"
                v-model="proformName"
        />
      </div>

      <div class="table-filter__item"
           v-if="navTabs.products.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['product_Handmade', 'proform_yes', 'proform_no'])"></span>
        <DefaultSelect
                :options="selectHandmade"
                :label="$t('product_Handmade.localization_value.value')"
                v-model="proformName"
                @change="changeHandmade"
                :optionsLabel="'label'"
                :selected="proformHandmadeActive"
        />
      </div>


      <div class="table-filter__item"
           v-if="navTabs.packaging.active || navTabs.labelPackaging.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['packaging_NamePackage'])"></span>
        <DefaultInput
                :label="$t('packaging_NamePackage.localization_value.value')"
                :type="'text'"
                v-model="packagingName"
        />
      </div>
      <div class="table-filter__item"
           v-if="navTabs.packaging.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['packaging_Weight'])"></span>
        <DefaultInput
                :label="$t('packaging_Weight.localization_value.value')"
                :type="'text'"
                v-model="packagingWeight"
        />
      </div>

      <div class="table-filter__item"
           v-if="navTabs.packaging.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['packaging_Length'])"></span>
        <DefaultInput
                :label="$t('packaging_Length.localization_value.value')"
                :type="'text'"
                v-model="packagingLength"
        />
      </div>

      <div class="table-filter__item"
           v-if="navTabs.packaging.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['packaging_Width'])"></span>
        <DefaultInput
                :label="$t('packaging_Width.localization_value.value')"
                :type="'text'"
                v-model="packagingWidth"
        />
      </div>

      <div class="table-filter__item"
           v-if="navTabs.packaging.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['packaging_Height'])"></span>
        <DefaultInput
                :label="$t('packaging_Height.localization_value.value')"
                :type="'text'"
                v-model="packagingHeight"
        />
      </div>

      <template  v-if="navTabs.simpleProduct.active">

<!--        <div class="table-filter__item" v-if="$store.getters.getIsAdminRights === 'admin'">-->
<!--          <UserSelect-->
<!--              :userSelected="simpleProductUser"-->
<!--              :typeSelect="'users'"-->
<!--              @changeUser="changeUsersFilter"-->
<!--              :userEmail="true"-->
<!--          />-->
<!--        </div>-->


        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['product_simpleProductName'])"></span>
          <DefaultInput
              :label="$t('product_simpleProductName.localization_value.value')"
              :type="'text'"
              v-model="simpleProductName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['product_nameCyrillic'])"></span>
          <DefaultInput
              :label="$t('product_nameCyrillic.localization_value.value')"
              :type="'text'"
              v-model="simpleProductNameCyrillic"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_PricePerItem'])"></span>
          <DefaultInput
              :label="$t('common_PricePerItem.localization_value.value')"
              :type="'text'"
              v-model="simpleProductPrice"
          />
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_ItemsQty'])"></span>
          <DefaultInput
              :label="$t('common_ItemsQty.localization_value.value')"
              :type="'text'"
              v-model="simpleProductCount"
          />
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['proform_hSCodeCode'])"></span>
          <DefaultInput
              :label="$t('proform_hSCodeCode.localization_value.value')"
              :type="'text'"
              v-model="simpleProductHSCode"
          />
        </div>
      </template>

      <template  v-if="navTabs.expenses.active || navTabs.incomes.active">
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['expenses_Name'])"></span>
          <DefaultInput
                  :label="$t('expenses_Name.localization_value.value')"
                  :type="'text'"
                  v-model="expensesName"
          />
        </div>
      </template>

      <template  v-if="navTabs.expenses.active">
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['expenses_ExpensesPayee'])"></span>
          <DefaultSelect
                  :options="selectExpensesType"
                  :label="$t('expenses_ExpensesPayee.localization_value.value')"
                  v-model="expensesType"
                  @change="changeExpensesType"
                  :otherValue="'translation'"
                  :selected="expensesType"
          />
        </div>
      </template>


    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {EXPENSES_SYSTEM_TYPES} from "../../../../../../../staticData/staticVariables";
  // import UserSelect from "../../../../../../coreComponents/UserSelect/UserSelect";

  export default {
    name: "ProfilesFilter",
    components: {
      DefaultSelect,
      FilterBlock,
      MainButton,
      DefaultInput,
      // UserSelect,
    },

    props: {
      navTabs: Object,
      showFilter: Boolean,
      filterGetParams: Object,
      selectHandmade: Array,
    },

    mixins: [filterFunc],

    data() {
      return {
        // date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        proformName: this.filterGetParams.proformName ? this.filterGetParams.proformName : '',
        proformHandmade: this.filterGetParams.proformHandmade ? this.filterGetParams.proformHandmade : '',

        packagingName: this.filterGetParams.packagingName ? this.filterGetParams.packagingName : '',
        packagingWeight: this.filterGetParams.packagingWeight ? this.filterGetParams.packagingWeight : '',
        packagingWidth: this.filterGetParams.packagingWidth ? this.filterGetParams.packagingWidth : '',
        packagingLength: this.filterGetParams.packagingLength ? this.filterGetParams.packagingLength : '',
        packagingHeight: this.filterGetParams.packagingHeight ? this.filterGetParams.packagingHeight : '',


        /**
         * Simple Product profile filter
         */
        simpleProductUser: this.filterGetParams.simpleProductUser ? this.filterGetParams.simpleProductUser : '',
        simpleProductName: this.filterGetParams.simpleProductName ? this.filterGetParams.simpleProductName : '',
        simpleProductNameCyrillic: this.filterGetParams.simpleProductNameCyrillic ? this.filterGetParams.simpleProductNameCyrillic : '',
        simpleProductPrice: this.filterGetParams.simpleProductPrice ? this.filterGetParams.simpleProductPrice : '',
        simpleProductCount: this.filterGetParams.simpleProductCount ? this.filterGetParams.simpleProductCount : '',
        simpleProductHSCode: this.filterGetParams.simpleProductHSCode ? this.filterGetParams.simpleProductHSCode : '',


        /**
         * Expenses filter
         */
        expensesName: this.filterGetParams.expensesName ? this.filterGetParams.expensesName : '',
        expensesType: this.filterGetParams.expensesType ? {translation: this.filterGetParams.expensesType} : '',
        expensesTypeId: this.filterGetParams.expensesTypeId ? this.filterGetParams.expensesTypeId : '',


        proformHandmadeActive: '',
        date: '',
        name: '',
        id: '',

        options: [{}],

        selectExpensesType: [
          EXPENSES_SYSTEM_TYPES.PAYEE_TYPE,
          EXPENSES_SYSTEM_TYPES.DEFAULT_TYPE,
          EXPENSES_SYSTEM_TYPES.BANK_TYPE,
        ],

        filterCounts: [
          'proformName',
          'proformHandmade',
          'packagingName',
          'packagingWeight',
          'packagingWidth',
          'packagingLength',
          'packagingHeight',


          'simpleProductUser',
          'simpleProductName',
          'simpleProductNameCyrillic',
          'simpleProductPrice',
          'simpleProductCount',
          'simpleProductHSCode',


          'expensesName',
          'expensesType',
          'expensesTypeId',
        ],
      }
    },

    mounted() {
      this.checkShopSelectActive()
    },

    watch: {
      filterGetParams(newVal) {
        // this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.proformName = newVal.proformName ? newVal.proformName : ''
        this.proformHandmade = newVal.proformHandmade ? newVal.proformHandmade : ''
        this.packagingName = newVal.packagingName ? newVal.packagingName : ''
        this.packagingWeight = newVal.packagingWeight ? newVal.packagingWeight : ''
        this.packagingWidth = newVal.packagingWidth ? newVal.packagingWidth : ''
        this.packagingLength = newVal.packagingLength ? newVal.packagingLength : ''
        this.packagingHeight = newVal.packagingHeight ? newVal.packagingHeight : ''

        /**
         * Simple Product profile filter
         */
        this.simpleProductUser = newVal.simpleProductUser ? newVal.simpleProductUser : ''
        this.simpleProductName = newVal.simpleProductName ? newVal.simpleProductName : ''
        this.simpleProductNameCyrillic = newVal.simpleProductNameCyrillic ? newVal.simpleProductNameCyrillic : ''
        this.simpleProductPrice = newVal.simpleProductPrice ? newVal.simpleProductPrice : ''
        this.simpleProductCount = newVal.simpleProductCount ? newVal.simpleProductCount : ''
        this.simpleProductHSCode = newVal.simpleProductHSCode ? newVal.simpleProductHSCode : ''

        /**
         * Expenses filter
         */
        this.expensesName = newVal.expensesName ? newVal.expensesName : ''
        this.expensesType = newVal.expensesType ? {translation: newVal.expensesType} : ''
        this.expensesTypeId = newVal.expensesTypeId ? newVal.expensesTypeId : ''


        this.checkShopSelectActive()
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')
        data.expensesType = this.expensesType.translation

        this.$emit('changeFilter', data)
      },

      changeHandmade(val) {
        this.proformHandmade = val.value
        this.proformHandmadeActive = val
      },

      checkShopSelectActive() {
        this.proformHandmadeActive = this.checkSelectActiveMixin(this.selectHandmade, parseInt(this.proformHandmade), 'value')
      },

      changeExpensesType(val) {
        this.expensesType = val
        this.expensesTypeId = val.value
      },
    },
  }
</script>

<style scoped>

</style>
