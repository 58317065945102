<template>
  <div class="fragment">
    <template
            v-if="$store.getters.getOrderSendFrom.length > 0 && $store.getters.getOrderSendFromLoading !== true">
      <div class="custom-row pt-4">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getOrderSendFrom"
             :key="index"
             :item="item"
        >
          <div class="case-cart">
            <div class="case-cart__inner">

              <div class="case-cart__head">

                <div class="case-cart__title case-cart__title--status">
                  <div>
                    {{item.company_name}}
                  </div>
                </div>

                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col custom-col--50"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="case-cart__label">
                      {{$t('common_name.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{item.first_name}}
                    </div>
                  </div>

                  <div class="case-cart__col custom-col custom-col--50"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="case-cart__label">
                      {{$t('common_surName.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{item.last_name}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                          class="secondary-brown"
                          :value="$t('common_toArchive.localization_value.value')"
                          :ico="true"
                          :typeIco="'delete-brown'"
                          @click.native="deleteItem(item.id, index)"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                          :value="$t('common_edit.localization_value.value')"
                          :ico="true"
                          :typeIco="'edit'"
                          @click.native="editItem(item.id)"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-bottom-btn"
           v-if="$store.getters.getOrderSendFromCommonList.next_page_url !== null && $store.getters.getOrderSendFrom.length > 0">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getOrderSendFromCommonList.next_page_url !== null && $store.getters.getOrderSendFrom.length > 0"
                  @click.native="$emit('showMore')"
                  v-bind:class="{'disabled-btn' : $store.getters.getNextExpensesTypesPage}"
                  :count="$store.getters.getOrderSendFromCommonList.total - $store.getters.getOrderSendFromForPage < $store.getters.getOrderSendFromForPage ?
                $store.getters.getOrderSendFromCommonList.total - $store.getters.getOrderSendFromForPage:
                $store.getters.getOrderSendFromForPage"
          />
        </div>
      </div>
    </template>
    <template
            v-if="$store.getters.getOrderSendFrom.length === 0 && $store.getters.getOrderSendFromLoading === false">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <SendFromDataPopup
        v-if="isModalOrderSendFromPopup"
        :type="'edit'"
        :id="idSelectedItem"
        @reload="$emit('reload')"
        @close="isModalOrderSendFromPopup = false"
    />

  </div>

</template>

<script>
  import CaseCartButton from "../../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";
  import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import SendFromDataPopup from "@/components/coreComponents/Popups/SendFromDataPopup/SendFromDataPopup";

  export default {
    name: "ProfilesSendFromTable",
    components: {SendFromDataPopup, ShowMore, NoResult, CaseCartButton},

    props: {
      contain: Array,
      countFilterParams: Number,
    },

    data() {
      return {
        isModalOrderSendFromPopup: false,
        idSelectedItem: null,
      }
    },


    methods: {

      editItem(id) {
        this.idSelectedItem = id
        this.isModalOrderSendFromPopup = true
        // this.$store.dispatch('getExpensesTypes', id).then(() => {
        //   this.changeExpensesTypesPopup(true)
        // })
      },

      deleteItem(id, index) {
        let text = {
          title: 'account_sendToArchive',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.$store.dispatch('deleteOrderSendFrom', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              let sendFrom = this.$store.getters.getOrderSendFrom
              sendFrom.splice(index, 1)

              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },
      //
      // changeExpensesTypesPopup(val) {
      //   this.isModalExpensesTypesPopup = val
      // },
    }

  }
</script>

<style scoped>

</style>
