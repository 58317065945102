<template>
  <modal
          @close="$emit('close')"
          class="profiles-package-modal profiles-package"
  >
    <template slot="header">
      <div v-if="!edit"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'common_CreateItem',
                      ])"></div>
        {{$t('common_CreateItem.localization_value.value')}}
      </div>
      <div v-else
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'common_ChangeItem',
                      ])"></div>
        {{$t('common_ChangeItem.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="profiles-package__content">

        <div class="order-create__row custom-row" v-if="isAdmin">
          <div class="order-create__col custom-col custom-col--50 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
            <UserSelect
                    :validationUser="IS.data.User.validation.userId"
                    :validationTxtUser="IS.data.User.validationTxt.userId"
                    :validationTranslateUser="'error_required'"
                    :userSelected="IS.data.User.user"
                    @changeUser="changeUsersFilter"
                    :typeSelect="'users'"
                    :userEmail="true"
            />
          </div>
        </div>

        <div class="profiles-package__row custom-row">

          <div class="profiles-package__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'common_fopName',
                      ])"></div>
            <DefaultInput
                    :label="$t('common_fopName.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': IS.validation.fopName}"
                    :errorTxt="$t(`${IS.validationTranslate.fopName}.localization_value.value`)"
                    :error="IS.validation.fopName"
                    v-model="IS.data.fopName"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'common_bankName',
                      ])"></div>
            <DefaultInput
                    :label="$t('common_bankName.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': IS.validation.bankName}"
                    :errorTxt="$t(`${IS.validationTranslate.bankName}.localization_value.value`)"
                    :error="IS.validation.bankName"
                    v-model="IS.data.bankName"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'common_bankSwift',
                      ])"></div>
            <DefaultInput
                    :label="$t('common_bankSwift.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': IS.validation.bankSwift}"
                    :errorTxt="$t(`${IS.validationTranslate.bankSwift}.localization_value.value`)"
                    :error="IS.validation.bankSwift"
                    v-model="IS.data.bankSwift"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'common_bankAccount',
                      ])"></div>
            <DefaultInput
                    :label="$t('common_bankAccount.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': IS.validation.bankAccount}"
                    :errorTxt="$t(`${IS.validationTranslate.bankAccount}.localization_value.value`)"
                    :error="IS.validation.bankAccount"
                    v-model="IS.data.bankAccount"
            />
          </div>
        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="profiles-package__btn">

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

<!--        <MainButton-->
<!--                v-if="!edit"-->
<!--                :value="$t('common_create.localization_value.value')"-->
<!--                v-bind:class="{'disabled-btn' : $store.getters.getProfilesPackagingBtn}"-->
<!--                class="profiles-package__btn-i"-->
<!--                @click.native="create"-->
<!--        />-->
        <MainButton
                :value="!edit ? $t('common_create.localization_value.value') : $t('common_update.localization_value.value')"
                v-bind:class="{'disabled-btn' : $store.getters.getProfilesPackagingBtn}"
                class="profiles-package__btn-i"
                @click.native="prepareSave"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultInput from '../../../../UI/inputs/DefaultInput/DefaultInput.vue'
  import UserSelect from "../../../../coreComponents/UserSelect/UserSelect";
  // import InputSum from "../../../../UI/inputs/InputSum/InputSum";
  // import InputQuantity from "../../../../UI/inputs/InputQuantity/InputQuantity";
  import {InvoiceSeller} from "@/components/globalModels/InvoiceSeller";

  export default {
    name: "AddingInvoiceSellerPopup",
    components: {
      UserSelect,
      Modal,
      MainButton,
      DefaultInput,
      // InputSum,
      // InputQuantity,
    },

    props: {
      id: Number,
      edit: {
        type: Boolean,
        default: false,
      }
    },

    watch: {
      loadUserAuthorizedData: function() {
        this.initialize()
      },
    },

    data() {
      return {
        IS: new InvoiceSeller(),
      }
    },

    mounted() {
      if (this.edit && this.loadUserAuthorizedData) {
        this.initialize()
      }
    },

    methods: {
      initialize() {
        let item = this.$store.getters.getInvoiceSellerItem

        if (this.edit && this.loadUserAuthorizedData){
          this.IS.setItem(item)
          this.IS.data.User.setUser(item.user)
        }
      },

      changeUsersFilter(value) {
        this.IS.data.User.setUser(value)
      },

      prepareSave() {

        if(!this.isAdmin){
          let userId = this.$store.getters.getUserProfile.id

          this.IS.data.User.setUserId(userId)
        }

        if (!this.IS.itemValidate(this.isAdmin)) return

        let data = this.IS.prepareData()

        let dispatchType = 'createInvoiceSeller'

        if(this.edit) {
          dispatchType = 'updateInvoiceSeller'
          data = {
            data: data,
            id: this.id
          }
        }

        this.$store.dispatch(dispatchType, data).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')

              this.$emit('reload')
              this.$emit('close')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      changeValueQuantity(val){
        this.IS.setCount(val)
      },

    }
  }

</script>

<style lang="scss">
  @import "../../../../../scss/colors";

  .profiles-package {

    .modal-component__inner {
      max-width: 750px;
    }


    &__content {

    }

    &__txt {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $borderBrown;
      margin-bottom: 30px;
    }

    &__row {
      margin-bottom: -15px;
    }

    &__alert {
      font-size: 12px;
      line-height: 14px;
      color: $black;
    }

    &__btn {
      display: flex;
      align-items: center;
    }

    &__btn-i {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

</style>
