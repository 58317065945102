<template>
  <modal
          @close="$emit('close')"
          class="profiles-package-modal profiles-package"
  >
    <template slot="header">
      <div v-if="!edit"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'product_AddingSimpleProductProfile',
                      ])"></div>
        {{$t('product_AddingSimpleProductProfile.localization_value.value')}}
      </div>
      <div v-else
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'product_editSimpleProductProfile',
                      ])"></div>
        {{$t('product_editSimpleProductProfile.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="profiles-package__content">
<!--        <div class="profiles-package__alert mb-4"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate([-->
<!--                        'packaging_PleaseAillDetail',-->
<!--                      ])"></div>-->
<!--          {{$t('product_PleaseAillDetail.localization_value.value')}}-->
<!--        </div>-->

        <div class="order-create__row custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
          <div class="order-create__col custom-col custom-col--50 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>

            <UserSelect
                    :validationUser="SP.validation.user"
                    :validationTxtUser="SP.validationTxt.user"
                    :validationTranslateUser="'error_required'"
                    :userSelected="SP.data.user"
                    @changeUser="changeUsersFilter"
                    :typeSelect="'users'"
                    :userEmail="true"
            />
          </div>
        </div>

        <div class="profiles-package__row custom-row">

          <div class="profiles-package__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'product_Name',
                      ])"></div>
            <DefaultInput
                    :label="$t('product_simpleProductName.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': SP.validation.name}"
                    :error="SP.validation.name"
                    :errorTxt="serverError ? SP.validationTxt.name: $t(`${SP.validationTranslate.name}.localization_value.value`)"
                    v-model="SP.data.name"
            />
          </div>
          <div class="profiles-package__col custom-col"
               v-if="!isBelarus || isAdmin"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'product_nameCyrillic',
                      ])"></div>
            <DefaultInput
                    :label="$t('product_nameCyrillic.localization_value.value')"
                    :type="'text'"
                    v-model="SP.data.nameCyrillic"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--25 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'common_PricePerItem',
                      ])"></div>
            <InputSum
                :label="$t('common_PricePerItem.localization_value.value')"
                :icoType="'dollar'"
                :numeric="true"
                :placeholder="'0'"
                v-bind:class="{'ui-no-valid': SP.validation.price}"
                :errorTxt="$t(`${SP.validationTranslate.price}.localization_value.value`)"
                :error="SP.validation.price"
                v-model="SP.data.price"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--25 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'common_ItemsQty',
                      ])"></div>
            <InputQuantity
                :label="$t('common_ItemsQty.localization_value.value')"
                v-bind:class="{'ui-no-valid': SP.validation.count}"
                :errorTxt="$t(`${SP.validationTranslate.count}.localization_value.value`)"
                :error="SP.validation.count"
                v-model="SP.data.count"
                @change="changeValueQuantity"
            />
          </div>
          <div class="custom-col mb-0"></div>
          <div class="profiles-package__col custom-col custom-col--50 custom-col--sm-100"
               v-if="!isBelarus || isAdmin"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'proform_hSCodeCode',
                      ])"></div>
            <DefaultInput
                :label="$t('proform_hSCodeCode.localization_value.value')"
                :type="'text'"
                v-model="SP.data.hSCode"
            />
          </div>
        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="profiles-package__btn">

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                v-if="!edit"
                :value="$t('common_create.localization_value.value')"
                v-bind:class="{'disabled-btn' : $store.getters.getProfilesPackagingBtn}"
                class="profiles-package__btn-i"
                @click.native="create"
        />
        <MainButton
                v-else
                :value="$t('common_update.localization_value.value')"
                v-bind:class="{'disabled-btn' : $store.getters.getProfilesPackagingBtn}"
                class="profiles-package__btn-i"
                @click.native="update"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultInput from '../../../../UI/inputs/DefaultInput/DefaultInput.vue'
  import UserSelect from "../../../../coreComponents/UserSelect/UserSelect";
  import InputSum from "../../../../UI/inputs/InputSum/InputSum";
  import {SimpleProduct} from "../../../../globalModels/SimpleProduct";
  import InputQuantity from "../../../../UI/inputs/InputQuantity/InputQuantity";

  export default {
    name: "AddingSimpleProductProfilePopup",
    components: {
      UserSelect,
      Modal,
      MainButton,
      DefaultInput,
      InputSum,
      InputQuantity,
    },

    props: {
      id: Number,
      edit: {
        type: Boolean,
        default: false,
      }
    },

    watch: {
      loadUserAuthorizedData: function() {
        this.initialize()
      },
    },

    data() {
      return {

        SP: new SimpleProduct(),

        data: {
          userId: '',
          name: '',
          nameCyrillic: '',
          count: 1,
          price: '',
        },

        // userForAdmin: '',

        serverError: false,

        // validation: {
        //   name: false,
        //   nameCyrillic: false,
        //   count: false,
        //   price: false,
        // },
        //
        // validationTranslate: {
        //   name: '',
        //   nameCyrillic: '',
        //   count: '',
        //   price: '',
        // },
        //
        // validationTxt: {
        //   name: false,
        //   nameCyrillic: false,
        //   count: false,
        //   price: false,
        // }
      }
    },

    mounted() {
      if (this.edit && this.loadUserAuthorizedData) {
        this.initialize()
      }
    },

    methods: {
      initialize() {
        let item = this.$store.getters.getSimpleProductProfileItem

        if (this.edit && this.loadUserAuthorizedData){
          this.SP.setItemForEdit(item)
        } else {

          this.SP.setItem(item)
        }

        // this.data = {
        //   name: item.name,
        //   nameCyrillic: item.name_cyrillic ? item.name_cyrillic : '',
        //   price: item.price,
        // }


        this.SP.setUser(item.user)
        // this.userForAdmin = item.user
      },

      changeUsersFilter(value) {
        this.SP.setUser(value)
        // this.userForAdmin = value
      },

      create() {

        if(!this.isAdmin){
          let userId = this.$store.getters.getUserProfile.id

          this.SP.setUserId(userId)
        }


        if (!this.SP.dataValidation({
          name: true,
          price: true,
          user: this.isAdmin,
        })) return


        let data = this.SP.prepareData()


        this.$store.dispatch('createSimpleProductProfile', data).then((response) => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordCreated')

            this.$emit('reload')
            this.$emit('close')
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;
            this.serverError = true;
            errors.package_name ? (this.validation.name = true, this.validationTxt.name = errors.package_name[0]) : false;
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },

      update() {

        if (!this.SP.dataValidation({
          name: true,
          price: true,
          user: this.isAdmin,
        })) return


        let data = this.SP.prepareData()

        this.$store.dispatch('updateSimpleProductProfile', {id: this.id, data: data}).then((response) => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordChanged')

            this.$emit('reload')
            this.$emit('close')
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;
            this.serverError = true;
            errors.package_name ? (this.validation.name = true, this.validationTxt.name = errors.package_name[0]) : false;
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },

      changeValueQuantity(val){
        this.SP.setCount(val)
      },

      // checkValidate() {
      //   let validationItems = {
      //     name: this.data.name,
      //     price: this.data.price,
      //   }
      //
      //   let validationOptions = {
      //     name: {type: ['empty']},
      //     price: {type: ['numeric', 'empty']},
      //   }
      //
      //   if(this.$store.getters.getIsAdminRights === 'admin'){
      //     validationItems.userForAdmin = this.userForAdmin
      //     validationOptions.userForAdmin = {type: ['empty']}
      //   }
      //
      //   let validate = validation(validationItems, validationOptions);
      //   this.validation = validate.validation;
      //   this.validationTranslate = validate.validationError
      //
      //   return validate.isValidate
      // }
    }
  }

</script>

<style lang="scss">
  @import "../../../../../scss/colors";

  .profiles-package {

    .modal-component__inner {
      max-width: 750px;
    }


    &__content {

    }

    &__txt {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $borderBrown;
      margin-bottom: 30px;
    }

    &__row {
      margin-bottom: -15px;
    }

    &__alert {
      font-size: 12px;
      line-height: 14px;
      color: $black;
    }

    &__btn {
      display: flex;
      align-items: center;
    }

    &__btn-i {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

</style>
