
export const ukrTreasuresMixin = {

  methods: {

    changeUser(user) {
      this.UT.data.User.setUserData(user)
    },

    save({edit, id = false}) {

      let saveType = 'createUkrTreasures'

      if(!this.UT.firstValidation()) return

      let data = this.UT.prepareSave()

      if(edit) {
        saveType = 'updateUkrTreasures'
        data = {
          data: data,
          id: id
        }
      }

      this.$store.dispatch(saveType, data).then((response) => {

        if(!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (this.UT.data.Files.getFiles().length > 0) {
              this.createFiles(this.getRespData(response).id).then(() => {
                this.$router.push(this.$store.getters.GET_PATHS.financeUkrTreasuresLink)
                if(edit) {
                  this.openNotify('success', 'common_notificationRecordChanged')
                } else {
                  this.openNotify('success', 'common_notificationRecordCreated')
                }
              })
              return
            }

            if(edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            this.$router.push(this.$store.getters.GET_PATHS.financeUkrTreasuresLink)
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },


    createFiles(id) {
      let reader = new FormData();

      this.UT.data.Files.getFiles().map((item, index) => {
        if (!this._.has(item, 'maxCount') && !item.maxSizeError) {
          reader.append(`file${index + 1}`, item.file);
        }
      })
      reader.append("listing_id", id);

      return this.$store.dispatch('addPhotoToUkrTreasures', {id: id, data: reader}).then((response) => {
        if(!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      })
    },

  }

}
